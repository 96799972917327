import * as TEMPLATE from "../../constants/templateConstants";

export const customColorObject = {
    backgroundColor: "",
    backgroundImage: "",
    colorType: "default",
    colorIndex: null,
    colorEntry: "black",
    color: "black",
    opacityEntry: "100",
    opacity: "100%"
};

export const customTextObject = {
    hide: false,
    font: "roboto",
    fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontWeight: "normal",
    fontSizing: "points",
    fontSizeEntry: "",
    fontSize: "",
    variant: "",
    fontStyle: "normal",
    textAlign: "left",  // left center right
    icon: "",
    ...customColorObject
}


export function getCustomTextObject(templateType) {
    let obj = {...customTextObject};

    obj.fontSizeEntry = getDefaultVariant(templateType).fontSizeEntry;
    obj.fontSize = getDefaultVariant(templateType).fontSize;
    obj.variant = getDefaultVariant(templateType).variant;

    return obj;
}

const customRadioObject = {
    backgroundColor: "",
    backgroundImage: "",
    colorType: "default",
    colorIndex: null,
    colorEntry: "black",
    color: "black"
}


export const customQuestionSingleSelect = {
    indentEntry: 0,
    indent: "",
    arrange: "row",             // row, side, wrap
    state: "",
    property: "",
    color: "default",           // default, custom
    selectedBorder: customRadioObject,
    selectedBackground: customRadioObject,
    selectedControl: customRadioObject,
    selectedText: customRadioObject,
    unselectedBorder: customRadioObject,
    unselectedBackground: customRadioObject,
    unselectedControl: customRadioObject,
    unselectedText: customRadioObject
}

export const customLikertObject = {
    alignAnswersRight: false,
    alignQuestionsAndAnswers: false,
    title: "none",
    titleType: "none",     // standard, graybar
    titleLeft: "",
    titleCenter: "",
    titleRight: "",
    ...customTextObject
}

export function getCustomLikertObject(templateType) {
    let obj = {...customLikertObject};

    let textDefault = getDefaultVariant(templateType);

    obj.fontSizeEntry = textDefault.fontSizeEntry;
    obj.fontSize = textDefault.fontSize;
    obj.variant = textDefault.variant;

    return obj;
}

export const customGroupObject = {
    ...customLikertObject
}

export const customQuestionObject =  {
    questionType: "",
    displayAs: "single",        // single, likert
    style: "radios",            // radios, ddlb, standard, clear, outlined
    likertStyle: "radios",      // radios, buttongroup (squares), circle
    circleType: "standard",     // standard, primary, alternate
    showSelectedLabel: "no",    //no, yes
    ...customLikertObject,
    ...customQuestionSingleSelect
};

export function getCustomQuestionObject(questionType) {
    let obj = {...customQuestionObject};

    obj.questionType = questionType;

    return obj;
}

export function getDefaultVariant(templateType = "questionnaire", titleType = "appHeaderText") {

    // NOTE: taken from QD Portal: getTitleDefaultVariant() in displayDefObjConstants.js

    let fontDefault = {
        variant: "",
        fontSizeEntry: "",
        fontSize: ""
    };

    // const h1FontSize = '36px';  // Questionnaire
    // const h2FontSize = '31px';  // Category
    // const h3FontSize = '26px';  // Group
    // const h4FontSize = '21px';  // Question
    // const h5FontSize = '16px';  // Answer
    // const h6FontSize = '16px';  // Info/Comment Gray
    // const captionFontSize = '12px'; // Label
    // const subtitle1FontSize = '12px';  // Label Gray
    // const subtitle2FontSize = '12px';  // Label Secondary Color
    // const body1FontSize = '14px';
    // const body2FontSize = '14px'; // Italic
    // const overlineFontSize = '10px';
    // const buttonFontSize = '32px';

    switch(templateType) {
        case TEMPLATE.TYPE.QUESTIONNAIRE:
            switch(this.props.titleType) {
                case "name":
                    fontDefault.variant = "h1";
                    fontDefault.fontSizeEntry = "36";
                    fontDefault.fontSize = "36pt";
                    break;
                case "desc":
                    fontDefault.variant = "h5";
                    fontDefault.fontSizeEntry = "16";
                    fontDefault.fontSize = "16pt";
                    break;
                case "script":
                case "scriptBefore":
                    fontDefault.variant = "h5";
                    fontDefault.fontSizeEntry = "16";
                    fontDefault.fontSize = "16pt";
                    break;
                case "scriptAfter":
                    fontDefault.variant = "h5";
                    fontDefault.fontSizeEntry = "16";
                    fontDefault.fontSize = "16pt";
                    break;
                case "close-text1":
                    fontDefault.variant = "h3";
                    fontDefault.fontSizeEntry = "26";
                    fontDefault.fontSize = "26pt";
                    break;
                case "close-text2":
                    fontDefault.variant = "h4";
                    fontDefault.fontSizeEntry = "21";
                    fontDefault.fontSize = "21pt";
                    break;
                case "close-text3":
                    fontDefault.variant = "h5";
                    fontDefault.fontSizeEntry = "16";
                    fontDefault.fontSize = "16pt";
                    break;
                default:
                    console.log("ArgoTextDisplayDefinition.js Switch default E02"); // eslint-disable-line
            }
            break;
        case TEMPLATE.TYPE.CATEGORY:
            switch(this.props.titleType) {
                case "name":
                    fontDefault.variant = "h2";
                    fontDefault.fontSizeEntry = "31";
                    fontDefault.fontSize = "31pt";
                    break;
                case "description":
                    fontDefault.variant = "h5";
                    fontDefault.fontSizeEntry = "16";
                    fontDefault.fontSize = "16pt";
                    break;
                case "script":
                    fontDefault.variant = "h5";
                    fontDefault.fontSizeEntry = "16";
                    fontDefault.fontSize = "16pt";
                    break;
                case "header-left":
                    fontDefault.variant = "h4";
                    fontDefault.fontSizeEntry = "21";
                    fontDefault.fontSize = "21pt";
                    break;
                case "header-center":
                    fontDefault.variant = "h4";
                    fontDefault.fontSizeEntry = "21";
                    fontDefault.fontSize = "21pt";
                    break;
                case "header-right":
                    fontDefault.variant = "h4";
                    fontDefault.fontSizeEntry = "21";
                    fontDefault.fontSize = "21pt";
                    break;
                default:
                    console.log("ArgoTextDisplayDefinition.js Switch default E03"); // eslint-disable-line
            }
            break;
        case TEMPLATE.TYPE.GROUP:
            switch(this.props.titleType) {
                case "name":
                    fontDefault.variant = "h3";
                    fontDefault.fontSizeEntry = "26";
                    fontDefault.fontSize = "26pt";
                    break;
                case "description":
                    fontDefault.variant = "h5";
                    fontDefault.fontSizeEntry = "16";
                    fontDefault.fontSize = "16pt";
                    break;
                default:
                    console.log("ArgoTextDisplayDefinition.js Switch default E04"); // eslint-disable-line
            }
            break;
        case TEMPLATE.TYPE.QUESTION:
            switch(this.props.titleType) {
                case "name":
                    fontDefault.variant = "h6";
                    fontDefault.fontSizeEntry = "16";
                    fontDefault.fontSize = "16pt";
                    break;
                case "description":
                    fontDefault.variant = "body1";
                    fontDefault.fontSizeEntry = "14";
                    fontDefault.fontSize = "14pt";
                    break;
                case "label":
                    fontDefault.variant = "caption";
                    fontDefault.fontSizeEntry = "12";
                    fontDefault.fontSize = "12pt";
                    break;
                default:
                    console.log("ArgoTextDisplayDefinition.js Switch default E05"); // eslint-disable-line
            }
            break;
        default:
            console.log("ArgoTextDisplayDefinition.js Switch default E06"); // eslint-disable-line
    }

    return fontDefault;
};