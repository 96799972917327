import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {TableGrid, TableView} from '../../containers';
import {getTemplateList, getTemplateReferences, updatePageAndGetList, updateSortAndGetList} from "../../actions/templateListActions";
import QuestionCreateEditDrawer, {questionCreateEditDrawerProps} from "../drawers/QuestionCreateEditDrawer";
import QuestionReviewDrawer, {questionReviewDrawerProps} from "../drawers/QuestionReviewDrawer";
import ReferencesDrawer, {referencesDrawerProps} from "../drawers/ReferencesDrawer";
import {openDrawer} from "../../actions/drawerActions";
import FilterPopOver from "../dialogs/filters/FilterPopOver";
import {getLabel, supported} from "../../constants/questionTypeConstants";
import * as metadataTemplateTypes from "../../constants/metadataTemplateTypes";
import * as TEMPLATE from "../../constants/templateConstants";
import * as SYSTEM from "../../constants/systemConstants";
import formatCopyText from "../../utilities/formatCopyText";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {QD_URL} from "../../constants/systemConstants";
import ArgoTemplateListCreateFabHook from "../common/ArgoTemplateListCreateFabHook";
import ArgoListTitleAndLoadingAnimationHook from "../common/ArgoListTitleAndLoadingAnimationHook";


class QuestionList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            filter: false,
            createMode: false
        };
    }

    componentDidMount(){
        this.setState({loading: true}, () => this.props.getTemplateList(this.props.filter, TEMPLATE.TYPE.QUESTION, TEMPLATE.MODE.CURRENT));
    }

    componentWillReceiveProps(nextProps) {

        // Called twice, first pass set filter false, then on second call list is loaded and loading animation can be stopped
        if (this.state.filter) {
            this.setState({filter: false});
        }
        else if (this.state.loading) {
            if (SYSTEM.USE_TIMER_DELAY) {
                // NOTE: Only use for local testing of loading render animation, before using for PRODUCTION set SYSTEM.USE_TIMER_DELAY_AMOUNT = false in systemConstants.js
                setTimeout(this.delayedSetLoadingFalse, SYSTEM.USE_TIMER_DELAY_AMOUNT); // 1000ms = 1 second, // Used to test loading animation rendering
            }
            else {
                this.setState({loading: false});
            }
        }

    }

    // Used to test loading animation rendering
    delayedSetLoadingFalse = () => {
        this.setState({loading: false});
    }

    setLoadingStateTrue = (callback, parm1 = null) => {
        // If a parameter is passed then pass it to call back, should be the template of the item to be saved edit or created
        if (parm1 === null) {
            this.setState({loading: true, filter: false}, () => callback());
        } else {
            this.setState({loading: true, filter: false}, () => callback(parm1));
        }
    }

    onPageChange = (page, size, event) => {
        this.setState({loading: true, filter: true}, () => this.props.updatePageAndGetList(page, size, this.props.filter, TEMPLATE.TYPE.QUESTION, TEMPLATE.MODE.CURRENT, event));
    };

    onSortChange = (sort, direction) => {
        this.setState({loading: true, filter: true}, () => this.props.updateSortAndGetList(sort, direction, this.props.filter, TEMPLATE.TYPE.QUESTION, TEMPLATE.MODE.CURRENT));
    };

    openEdit = (templateKey, id, templateMode, notUsed, row) => {
        if (!templateKey && this.props.match && this.props.match.params) {
            templateKey = this.props.match.params.templateKey;
        }

        this.props.openDrawer(questionCreateEditDrawerProps, <QuestionCreateEditDrawer templateKey={templateKey} templateId={id} filter={this.props.filter} templateMode={templateMode} row={row} setLoadingState={this.setLoadingStateTrue}/>);
    };

    openCreate = () => {
        this.props.openDrawer(questionCreateEditDrawerProps, <QuestionCreateEditDrawer createMode={true} setLoadingState={this.setLoadingStateTrue}/>);
        // if (e !== undefined) {
        //     e.preventDefault();
        // }
        this.setState({createMode: true});
    };

    openQuestionReviewDrawer = (templateKey, id, name, row, event) => {
        if (!templateKey && this.props.match && this.props.match.params) {
            templateKey = this.props.match.params.templateKey;
        }
        formatCopyText(row, event, "Question:");
        this.props.openDrawer(questionReviewDrawerProps, <QuestionReviewDrawer templateKey={templateKey} templateId={id}/>);
    };

    viewReferences = (templateKey, id) => {
        this.props.getTemplateReferences(TEMPLATE.TYPE.QUESTION, id);
        this.props.openDrawer(referencesDrawerProps, <ReferencesDrawer templateId={id} templateType={TEMPLATE.TYPE.QUESTION} title="This question is referenced in the following:"/>);
    };

    render() {

        const customActions = [
            {
                func: this.openQuestionReviewDrawer,
                label: "Review"
            },
            {
                func: this.openEdit,
                label: "Edit"
            },
            {
                func: this.viewReferences,
                label: "References"
            }
        ];

        // Used by the versions list and stored in the version filters
        let defaultActions = {
            edit: this.openEdit,
            create: this.openCreate,
            review: this.openQuestionReviewDrawer
        };

        let supportedTypes = {};
        Object.keys(supported).forEach(key => {
            supportedTypes[key] = getLabel[key];
        });

        return (
            <div id="QuestionList-container-div" style={{height: "100%", padding: "15px", marginTop: '64px'}}>

                <ArgoListTitleAndLoadingAnimationHook fileName="QuestionList" listTitle="Questions" loading={this.state.loading} filter={this.state.filter}/>

                <div style={{clear: "both"}}></div>

                <Card id="QuestionList-card"
                      variant="outlined"
                      sx={{border: "1px solid lightgray", height: "100%", boxShadow: "2px 4px whitesmoke"
                      }}>
                    <CardContent id="QuestionList-card-content">

                        <TableView
                            id="QuestionList-table-view"
                            templateType={TEMPLATE.TYPE.QUESTION}
                            templateMode={TEMPLATE.MODE.CURRENT}
                            setLoadingState={this.setLoadingStateTrue}
                            filter={<FilterPopOver page="questions"
                                                   templateType={TEMPLATE.TYPE.QUESTION}
                                                   templateMode={TEMPLATE.MODE.CURRENT}
                                                   filterName={TEMPLATE.FILTER.current.question}
                                                   submitValue={this.props.getTemplateList}
                                                   statuses={["All", "Draft", "Published"]}
                                                   showQuestionType={true}
                                                   types={supportedTypes}
                                                   setLoadingState={this.setLoadingStateTrue}
                                                   showSelectTag={true}
                            />}
                        >

                            <TableGrid
                                id="QuestionList-table-grid"
                                displayType = {"questions"}
                                dataSource = {this.props.questionTemplateList}
                                nameDrawer = {this.openQuestionReviewDrawer}
                                link = {QD_URL +"/question-editor/"}
                                customActions = {customActions}
                                pageState = {this.props.filter}
                                onPageChange = {this.onPageChange}
                                sortState = {this.props.filter}
                                onSortChange = {this.onSortChange}
                                type={metadataTemplateTypes.QuestionTemplate}
                                templateType={TEMPLATE.TYPE.QUESTION}
                                templateMode={TEMPLATE.MODE.CURRENT}
                                defaultActions={defaultActions}
                                showType={true}
                                showReferencesAndVersionsSameColumn={true}
                            />
                        </TableView>

                        <ArgoTemplateListCreateFabHook
                            fileName="QuestionList"
                            fieldName="fab"
                            hoverText="Create Question"
                            onClick={this.openCreate}
                        />

                    </CardContent>

                </Card>

            </div>
        );
    }
}

QuestionList.propTypes = {
    questionTemplateList: PropTypes.array,
    match: PropTypes.object,
    location: PropTypes.object,
    filter: PropTypes.object
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getTemplateList, getTemplateReferences, updatePageAndGetList, updateSortAndGetList, openDrawer}, dispatch);
}

function mapStateToProps(state) {
    return {
        questionTemplateList: state.questionTemplateList,
        filter: state.filter[TEMPLATE.FILTER.current.question] || {}  //Get correct filter from constant
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionList);
