import * as icon from "../constants/iconConstants";
import React from "react";
import * as portal from "./portal";

export const REPORTING_CATEGORY_QUESTIONNAIRE = "reporting-category-questionnaire";
export const REPORTING_CATEGORY_QUESTION = "reporting-category-question";

export const IS_STRINGIFIED_OBJ = "IS_STRINGIFIED_OBJ";
export const IS_STRING = "IS_STRING";

export function checkMetadataObjectExist(metadata, obj) {
    return metadata.hasOwnProperty(obj);
}

export function getCustomMetadataValue(metadata, obj, valueType) {
    return valueType === IS_STRINGIFIED_OBJ ? JSON.parse(metadata[obj]) : metadata[obj];
}


export function isHiddenMetadata(metadataName)  {
    // ZZZZZ YYYYY QQQQQ
    let  hideMetadata = false;
    let  hideAllMetadata = true; // To show specific types of metadata set hideAllMetadata = true, and change specific sections from true to false.

    // METADATA HIDE/SHOW
    switch (metadataName) {
        case icon.ICON_POSITION:
        case icon.ICON_TYPE:
        case icon.ICON_COLOR:
        case icon.ICON_COLOR_OBJ:
        case icon.ICON_TITLES:
            hideMetadata = hideAllMetadata && true;
            break;
        case portal.USER_SCRIPT_BEFORE:
        case portal.USER_SCRIPT_AFTER:
        case portal.CAT_SCRIPT:
        case portal.DISPLAY_AS: // Used by question render as button group option
            hideMetadata = hideAllMetadata && true;
            break;
        case portal.DISPLAY_DEF_RADIO_AS_BUTTON_GROUP:
        case portal.DISPLAY_DEF_BUTTON_AS_CIRCLE:
        case portal.DISPLAY_DEF_BUTTON_AS_CIRCLE_COLORS:
        case portal.DISPLAY_DEF_BUTTON_AS_CIRCLE_COLORS_ALT:
            hideMetadata = hideAllMetadata && true;
            break;
        case portal.DISPLAY_DEF_ALIGN_QUESTION_ANSWER_RIGHT:
        case portal.DISPLAY_DEF_LIKERT_QUESTION_ALIGN_WITH_ANSWERS:
        case portal.IMAGE_APP_TITLE_BAR_LEFT:
        case portal.IMAGE_APP_TITLE_BAR_CENTER:
        case portal.IMAGE_APP_TITLE_BAR_RIGHT:
        case portal.DISPLAY_DEF_SHOW_GROUP_STANDARD:
        case portal.DISPLAY_DEF_HIDE_BUTTONGROUP_NAME:
            hideMetadata = hideAllMetadata && true;
            break;
        case portal.DISPLAY_DEF_LIKERT_TITLE_LEFT:
        case portal.DISPLAY_DEF_LIKERT_TITLE_CENTER:
        case portal.DISPLAY_DEF_LIKERT_TITLE_RIGHT:
            hideMetadata = hideAllMetadata && true;
            break;
        default:
            break;
    }

    // Hide metadata related to Portal display.
    if (metadataName.includes("custom-ddo")) {
        hideMetadata = hideAllMetadata && true;  // TOGGLE: to see metadata entries for "custom-ddo..."
    }

    // custom-ui are metadata added by UI such as portal tab title, but do not require a custom-ddo
    if (metadataName.includes("custom-ui")) {
        hideMetadata = hideAllMetadata && true;  // TOGGLE: to see metadata entries for "custom-ui..."
    }

    if (metadataName === "sendJSON") {
        hideMetadata = hideAllMetadata && true;  // TOGGLE: to see metadata entry for send JSON object
    }

    // Note we can't hide metadata.REPORTING_CATEGORY_QUESTIONNAIRE because its in the config ans shows up in the metadata name autoselect.  would be good to fix this in the future and hide it.
    if (metadataName.includes(REPORTING_CATEGORY_QUESTIONNAIRE)) {
        hideMetadata = hideAllMetadata && true;
    }

    // Note we can't hide metadata.REPORTING_CATEGORY_QUESTION because its in the config ans shows up in the metadata name autoselect.  would be good to fix this in the future and hide it.
    if (metadataName.includes(REPORTING_CATEGORY_QUESTION)) {
        hideMetadata = hideAllMetadata && true;
    }

    return hideMetadata;
}


export function countNumberOfVisibleMetadataEntries(metadata) {
    let count = 0;

    for (let name in metadata) {
        if (!isHiddenMetadata(name)) {
            ++count;
        }
    }
    return count;
}


export function listMetadata (template, label = "", color = "darkgray") {
    let metadataArray = [];

    // Convert metadata to an array of objects to .map() function can be used.
    for (var key in template.metadata) {
        if (template.metadata.hasOwnProperty(key)) {
            // Don't display custom-ddo... objects since they are stringified objects
            if (!isHiddenMetadata(key)) {
                let value = template.metadata[key];
                metadataArray.push({key: key, value: value});
            }
        }
    }

    return (
        <div>
            <div style={{color: color}}>{label === "" ? "Metadata:" : (label + " Metadata:")}</div>
            {metadataArray.map((row) => {
                return (
                    <div style={{width: "100%", display: "flex", justifyContent: "flex-start", overflowWrap: "break-word"}}>
                        <div style={{color: "darkgray", padding: ".3% 1%"}}>Name:</div>
                        <div style={{padding: ".3% 1% .3% 0%", width: "22%"}}>{row.key}</div>
                        <div style={{color: "darkgray", padding: ".3% 1% .3% 0%"}}>Value:</div>
                        <div style={{padding: ".3% 0% .3% 0%", width: "68%"}}>{row.value}</div>
                    </div>
                );
            })}

            <div>&nbsp;</div>
        </div>
    );
}

export const definitionAndValueAndValueObj = {displayDefinition: "default", displayMetadataValue: "", displayMetadataValueObj: null};

export function setMetadataDefinitionAndValueAndValueObj(metadata, metadataPropertyName, valueObj, titlebar = false) {

    let tempObj = {...valueObj};

    // For categories if Title Bar is seleted then name and desc are forced to "HIDE" mode
    if (metadata.hasOwnProperty(metadataPropertyName)) {
        if (titlebar) {
            tempObj.displayDefinition = "hide";
        }
        else {
            // Note: "\"hide\"" occured in the past when a string was saved to the metadata value, this is a patch to allow those issues to work
            tempObj.displayMetadataValue = (metadata[metadataPropertyName] === "\"hide\"") ? "hide" : metadata[metadataPropertyName];

            if (tempObj.displayMetadataValue === "hide") {
                tempObj.displayDefinition = "hide";
            } else {
                tempObj.displayMetadataValueObj = tempObj.displayMetadataValue === "" ? "" : JSON.parse(tempObj.displayMetadataValue);
                tempObj.displayDefinition = "custom";
            }
        }
    }
    else {
        if (titlebar) {
            tempObj.displayDefinition = "hide";
        }
    }

    return tempObj;
}


export function setMetadata(metadataTag, metadataValue, template, updateTemplateFunction, depth) {

    let updatedTemplate = {...template};
    let tempMetadata = {...updatedTemplate.metadata};

    tempMetadata[metadataTag] = metadataValue;

    updateTemplateFunction(tempMetadata, depth);
}


export function deleteMetadata(metadataTag, template, updateTemplateFunction, depth) {

    let updatedTemplate = {...template};
    let tempMetadata = {...updatedTemplate.metadata};

    if (tempMetadata.hasOwnProperty(metadataTag)) {
        delete tempMetadata[metadataTag];
    }

    updateTemplateFunction(tempMetadata, depth);
}


export function setCustomMetadata(template, updateTemplateFunction, setStateCallbackFunction, fieldName, selected, metadataTag, metadataValueObj, depth = 0) {

    let updatedTemplate = {...template};
    let tempMetadata = {...updatedTemplate.metadata};

    let metadataValueStr = (typeof metadataValueObj === "string") ? metadataValueObj : JSON.stringify(metadataValueObj);

    // NOTE: Reporting category can be "None" its added to the DDLB however the metadata config list does not have it. So index is adjusted +1
    if ((selected === "default")||(selected === "None")) {
        if (tempMetadata.hasOwnProperty(metadataTag)) {
            delete tempMetadata[metadataTag];
        }
    }
    else {
        tempMetadata[metadataTag] = metadataValueStr;
    }

    let displayAsLikert = false; // this.state.displayAsLikert;
    let metadataSaveName = "";
    let metadataSaveObjName = "";
    let setStateFlag = true;
    switch(fieldName) {
        case "nameDisplayDefinition":
            metadataSaveName = "nameDisplayMetadataValue";
            metadataSaveObjName = "nameDisplayMetadataValueObj";
            break;
        case "descDisplayDefinition":
            metadataSaveName = "descDisplayMetadataValue";
            metadataSaveObjName = "descDisplayMetadataValueObj";
            break;
        case "typeDisplayDefinition":
            // Question custom can be displayed as radio mode or likert mode
            metadataSaveName = "typeDisplayMetadataValue";
            metadataSaveObjName = "typeDisplayMetadataValueObj";
            displayAsLikert = metadataValueObj.questionType === "RADIO" ? (metadataValueObj.displayAs === "likert") : false;
            break;
        case "labelDisplayDefinition":
            // Question Editor
            metadataSaveName = "labelDisplayMetadataValue";
            metadataSaveObjName = "labelDisplayMetadataValueObj";
            break;
        case "scriptDisplayDefinition":
            // Category Script
            metadataSaveName = "scriptDisplayMetadataValue";
            metadataSaveObjName = "scriptDisplayMetadataValueObj";
            break;
        case "leftDisplayDefinition":
            // Category title bar embedded text
            metadataSaveName = "leftDisplayMetadataValue";
            metadataSaveObjName = "leftDisplayMetadataValueObj";
            break;
        case "centerDisplayDefinition":
            // Category title bar embedded text
            metadataSaveName = "centerDisplayMetadataValue";
            metadataSaveObjName = "centerDisplayMetadataValueObj";
            break;
        case "rightDisplayDefinition":
            // Category title bar embedded text
            metadataSaveName = "rightDisplayMetadataValue";
            metadataSaveObjName = "rightDisplayMetadataValueObj";
            break;
        case "reportingCategory":
            // Questionnaires and Questions can be assigned a reporting category
            setStateFlag = false;
            break;
        // no default
    }

    if (setStateFlag) {
        setStateCallbackFunction({
            [fieldName]: selected,
            [metadataSaveName]: metadataValueStr,
            [metadataSaveObjName]: metadataValueObj,
            "displayAsLikert": displayAsLikert
        });
    }

    handleMetadataChange(template, updateTemplateFunction, tempMetadata, depth);

}


export function handleMetadataChange(template, updateTemplateFunction, metadata, depth) {
    let updatedTemplate = {...template};
    updatedTemplate.metadata = metadata;
    updateTemplateFunction(updatedTemplate, depth);
}
