import * as types from "../constants/actionConstants";

const initialState = {

};

export default function expanderReducer(state = initialState, action) {
    switch (action.type) {
        case types.EXPANDER_UPDATE:
            let obj = JSON.parse(JSON.stringify(state));
            if (!obj.hasOwnProperty(action.payload.parentName)) {
                obj[action.payload.parentName] = {};
            }

            if (!obj[action.payload.parentName].hasOwnProperty(action.payload.expanderName)) {
                obj[action.payload.parentName][action.payload.expanderName]={};
            }

            obj[action.payload.parentName][action.payload.expanderName].expanderSet = true;
            obj[action.payload.parentName][action.payload.expanderName].expandAll = action.payload.expanderObj.expandAll;
            obj[action.payload.parentName][action.payload.expanderName].expander = {};

            let objExpander = obj[action.payload.parentName][action.payload.expanderName].expander;
            if (action.payload.templateKey !== undefined) {
                objExpander[action.payload.templateKey] = {};
                objExpander[action.payload.templateKey].open = action.payload.expanderObj.expander[action.payload.templateKey].open;
            }
            else {
                // Iterate through the object
                for (const templateKey in action.payload.expanderObj.expander) {
                    if (action.payload.expanderObj.expander.hasOwnProperty(templateKey)) {
                        objExpander[templateKey] = {};
                        objExpander[templateKey].open = action.payload.expanderObj.expander[templateKey].open;
                    }
                }
            }

            return {
                ...state,
                ...obj
            };

        case types.EXPANDER_CLEAR:
            let clonedObj = JSON.parse(JSON.stringify(state));
            delete clonedObj[action.payload.parentName];
            return {
                ...clonedObj,
            };

        default:
            return state;
    }
}