export const CHECKBOX = "CHECKBOX";
export const RADIO = "RADIO";
export const YES_NO = "YES_NO";
export const LIKERT_CHECKBOX = "LIKERT_CHECKBOX";
export const LIKERT_RADIO = "LIKERT_RADIO";
export const DYNAMIC_CHECKBOX = "DYNAMIC_CHECKBOX";
export const DYNAMIC_RADIO = "DYNAMIC_RADIO";
export const NUMBER = "NUMBER";
export const PHONE = "PHONE";
export const DATE = "DATE";
export const DATE_RANGE = "DATE_RANGE";
export const EMAIL = "EMAIL";
export const FREE_TEXT = "FREE_TEXT";
export const IMAGE = "IMAGE";
export const INFO = "INFO";
export const MAP = "MAP";
export const CURRENCY = "CURRENCY";
export const ADDRESS_FULL = "ADDRESS_FULL";
export const ZIPPOSTAL_ONLY = "ZIPPOSTAL_ONLY";
export const STATE_ONLY = "STATE_ONLY";

// export const getQuestionTypeLabel = {
//     [CHECKBOX]: "Multiple Choice - Multi Select",
//     [RADIO]: "Multiple Choice - Single Select",
//     [YES_NO]: "Yes/No",
//     [LIKERT_CHECKBOX]: "LIKERT_CHECKBOX",
//     [LIKERT_RADIO]: "Linear Scale (Likert)",
//     [DYNAMIC_CHECKBOX]: "DYNAMIC_CHECKBOX",
//     [DYNAMIC_RADIO]: "DYNAMIC_RADIO",
//     [NUMBER]: "Number",
//     [PHONE]: "Phone",
//     [DATE]: "Date",
//     [DATE_RANGE]: "Date Range",
//     [EMAIL]: "Email",
//     [FREE_TEXT]: "Free Text",
//     // [IMAGE]: "Image",
//     // [INFO]: "Info",
//     // [MAP]: "Map",
//     [CURRENCY]: "CURRENCY",
//     [ADDRESS_FULL]: "Address",
//     [ZIPPOSTAL_ONLY]: "ZIP",
//     [STATE_ONLY]: "State",
// };

export const supported = {
    CHECKBOX,
    RADIO,
    YES_NO,
    LIKERT_RADIO,
    NUMBER,
    PHONE,
    DATE,
    DATE_RANGE,
    EMAIL,
    FREE_TEXT,
    CURRENCY,
    ADDRESS_FULL,
    ZIPPOSTAL_ONLY,
    STATE_ONLY,
};