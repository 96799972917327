import * as font from "../utilities/fontUtilities";
import * as tag from "../constants/customMetadataConstants";
import * as ddo from "../constants/customObjConstants";


export const RESPONSIVE_LABEL_DEFAULT = {
    ANSWERS_1_2: {
        "N1": "Poor",
        "N2": "Good"
    },
    ANSWERS_1_3: {
        "N1": "Poor",
        "N2": "Average",
        "N3": "Good"
    },
    ANSWERS_1_4: {
        "N1": "Very Poor",
        "N2": "Poor",
        "N3": "Good",
        "N4": "Very Good"
    },
    ANSWERS_1_5: {
        "N1": "Very Poor",
        "N2": "Poor",
        "N3": "Average",
        "N4": "Good",
        "N5": "Very Good"
    },
    ANSWERS_1_6: {
        "N1": "Extremely Poor",
        "N2": "Very Poor",
        "N3": "Poor",
        "N4": "Good",
        "N5": "Very Good",
        "N6": "Extremely Good"
    },
    ANSWERS_1_7: {
        "N1": "Extremely Poor",
        "N2": "Very Poor",
        "N3": "Poor",
        "N4": "Average",
        "N5": "Good",
        "N6": "Very Good",
        "N7": "Extremely Good"
    },
    ANSWERS_1_10: {
        "N1": "Extremely Poor",
        "N2": "Very Poor",
        "N3": "Poor",
        "N4": "Semi-Poor",
        "N5": "Below Average",
        "N6": "Above Average",
        "N7": "Semi-Good",
        "N8": "Good",
        "N9": "Very Good",
        "N10": "Extremely Good"
    },
    ANSWERS_0_10: {
        "N0": "Extremely Poor",
        "N1": "Very Poor",
        "N2": "Poor",
        "N3": "Semi-Poor",
        "N4": "Below Average",
        "N5": "Average",
        "N6": "Above Average",
        "N7": "Semi-Good",
        "N8": "Good",
        "N9": "Very Good",
        "N10": "Extremely Good"
    }
};


// Use responsive labels
// export function useResponsiveLabels(group, question, answers) {
//
//     let useResponsiveLabelsFlag = false;
//
//     // Priority if group or question level use of defaults declared in metadata
//     useResponsiveLabelsFlag = (tag.metadataExists(question, tag.USE_LIKERT_DEFAULT_RESPONSIVE_LABEL) || tag.metadataExists((group !== undefined) ? group : question, tag.USE_LIKERT_DEFAULT_RESPONSIVE_LABEL));
//
//     if (!useResponsiveLabelsFlag) {
//
//         // If not at group or question level check all the answers for a declared responsive label
//         // if any answer has metadata for responsive level
//         for (let i = 0; i < answers.length; i++) {
//             if (tag.metadataExists(answers[i], tag.USE_LIKERT_RESPONSIVE_LABEL) || tag.metadataExists(answers[i], tag.USE_LIKERT_DEFAULT_RESPONSIVE_LABEL)) {
//                 useResponsiveLabelsFlag = true;
//                 break;
//             }
//         }
//     }
//
//     return useResponsiveLabelsFlag;
// }





// parent template could be a group or question, but responsive ansers topography could be by answer
export function getResponsiveLabelProperties(parentTemplate, answer) {

    let textStyleObj = font.getDefaultOrCustomTextObj(parentTemplate, ddo.OBJ_TYPE.TEXT, ddo.OBJ_TEXT_TYPE.NAME, "responsive-label");


    let responsiveLabelSize = "12pt";
    let responsiveLabelColor = "black";
    let responsiveLabelAlign = "center";
    let responsiveLabelWeight = "normal";
    let responsiveLabelStyle = "normal";
    let responsiveLabelOpacity = "100%";
    let responsiveLabelFont = font.DEFAULT_FONT_FAMILY;
    let responsiveLabelsObj = null;

    if (textStyleObj.type === "default") {

        if (tag.metadataExists(parentTemplate, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_SIZE)) {
            responsiveLabelSize = (tag.metadataValue(parentTemplate, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_SIZE));
        }

        if (tag.metadataExists(parentTemplate, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_COLOR)) {
            responsiveLabelColor = (tag.metadataValue(parentTemplate, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_COLOR));
        }

        if (tag.metadataExists(parentTemplate, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_ALIGN)) {
            responsiveLabelAlign = (tag.metadataValue(parentTemplate, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_ALIGN));
        }

        if (tag.metadataExists(parentTemplate, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_WEIGHT)) {
            responsiveLabelWeight = (tag.metadataValue(parentTemplate, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_WEIGHT));
        }

        if (tag.metadataExists(parentTemplate, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_STYLE)) {
            responsiveLabelStyle = (tag.metadataValue(parentTemplate, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_STYLE));
        }

        if (tag.metadataExists(parentTemplate, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_OPACITY)) {
            responsiveLabelOpacity = (tag.metadataValue(parentTemplate, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_OPACITY));
        }

        if (tag.metadataExists(parentTemplate, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_FONT)) {
            responsiveLabelFont = (tag.metadataValue(parentTemplate, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_FONT));
        }

        // -----------------------------------------------------------------------------------------------------------------
        // Answer metadata overrides parent metadata
        // -----------------------------------------------------------------------------------------------------------------

        if (tag.metadataExists(answer, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_SIZE)) {
            responsiveLabelSize = (tag.metadataValue(answer, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_SIZE));
        }

        if (tag.metadataExists(answer, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_COLOR)) {
            responsiveLabelColor = (tag.metadataValue(answer, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_COLOR));
        }

        if (tag.metadataExists(answer, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_ALIGN)) {
            responsiveLabelAlign = (tag.metadataValue(answer, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_ALIGN));
        }

        if (tag.metadataExists(answer, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_WEIGHT)) {
            responsiveLabelWeight = (tag.metadataValue(answer, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_WEIGHT));
        }

        if (tag.metadataExists(answer, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_STYLE)) {
            responsiveLabelStyle = (tag.metadataValue(answer, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_STYLE));
        }

        if (tag.metadataExists(answer, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_OPACITY)) {
            responsiveLabelOpacity = (tag.metadataValue(answer, tag.DISPLAY_DEFAULT_RESPONSIVE_LABEL_OPACITY));
        }

        responsiveLabelsObj = {
            fontFamily: responsiveLabelFont,
            width: '100%',
            fontSize: responsiveLabelSize,
            textAlign: responsiveLabelAlign,
            color: responsiveLabelColor,
            colorEntry: responsiveLabelColor,
            fontWeight: responsiveLabelWeight,
            fontStyle: responsiveLabelStyle,
            opacity: responsiveLabelOpacity,
        }
    }
    else {

        responsiveLabelsObj = {
            fontFamily: textStyleObj.fontObj.fontFamily,
            width: '100%',
            fontSize: textStyleObj.fontObj.fontSize,
            textAlign: responsiveLabelAlign,
            color: textStyleObj.fontObj.colorType === "select" ? textStyleObj.fontObj.color : textStyleObj.fontObj.colorEntry,
            colorEntry: textStyleObj.fontObj.colorEntry,
            fontWeight: textStyleObj.fontObj.fontWeight,
            fontStyle: textStyleObj.fontObj.fontStyle,
            opacity: textStyleObj.fontObj.opacity,
        }

    }

    return responsiveLabelsObj;
}



