import React, {Component} from "react";
import PropTypes from "prop-types";
import * as questionTypes from "../../constants/questionTypeConstants";
import ArgoTextFieldSelectHook from "./ArgoTextFieldSelectHook";
import {ZIPPOSTAL_ONLY} from "../../constants/questionTypeConstants";


class ArgoQuestionFormatDefinition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            metadataName: "",
            metadataValue: "",
            customFormat: props.initTo
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({customFormat: nextProps.initTo})
    }


    showFormatOption = () => {
        return (this.props.questionType === questionTypes.LIKERT_RADIO || this.props.questionType === questionTypes.STATE_ONLY) ? false : true;

    };


    onSelectChange = (event, fieldName) => {

        let selected = event.target.value;

        let tempObj = {};
        switch(this.props.questionType) {
            case questionTypes.FREE_TEXT:
            case questionTypes.EMAIL:
            case questionTypes.PHONE:
            case questionTypes.NUMBER:
            case questionTypes.CURRENCY:
            case questionTypes.DATE:
            case questionTypes.ZIPPOSTAL_ONLY:
                tempObj = {
                    questionType: this.props.questionType,
                    style: "outlined",          // outlined, standard, filled
                    size: "small",             // small, medium
                    widthType: 'percentage',   // percentage, pixels
                    widthEntry: "100",          // numeric width
                    width: "100%",             // numeric width + "px"
                    max: "255",
                    multiline: false,
                    maxRows: 1,
                    labelPosition: "none",      // none, above, below
                    label: "",
                    labelDisplay: "default",    // default, custom
                    floatingLabel: "",
                    placeHolder: "",
                };
                break;
            case questionTypes.ADDRESS_FULL:
                tempObj = {
                    questionType: this.props.questionType,
                    style: "outlined",          // outlined, standard, filled
                    size: "small",             // small, medium
                    widthType: 'percentage',   // percentage, pixels
                    widthEntry: "100",          // numeric width
                    width: "100%",             // numeric width + "px"
                    max: "255",
                    multiline: false,
                    maxRows: 1,
                    labelPosition: "none",      // none, above, below
                    label: "",
                    labelDisplay: "default",    // default, custom
                    floatingLabel: "Street 1",
                    placeHolder: "",

                    style2: "outlined",          // outlined, standard, filled
                    size2: "small",             // small, medium
                    widthType2: 'percentage',   // percentage, pixels
                    widthEntry2: "100",          // numeric width
                    width2: "100%",             // numeric width + "px"
                    max2: "255",
                    labelPosition2: "none",      // none, above, below
                    label2: "",
                    labelDisplay2: "default",    // default, custom
                    floatingLabel2: "Street 2",
                    placeHolder2: "",

                    style3: "outlined",          // outlined, standard, filled
                    size3: "small",             // small, medium
                    widthType3: 'percentage',   // percentage, pixels
                    widthEntry3: "100",          // numeric width
                    width3: "100%",             // numeric width + "px"
                    max3: "255",
                    labelPosition3: "none",      // none, above, below
                    label3: "",
                    labelDisplay3: "default",    // default, custom
                    floatingLabel3: "City",
                    placeHolder3: "",

                    style4: "outlined",          // outlined, standard, filled
                    size4: "small",             // small, medium
                    widthType4: 'percentage',   // percentage, pixels
                    widthEntry4: "100",          // numeric width
                    width4: "100%",             // numeric width + "px"
                    max4: "255",
                    labelPosition4: "none",      // none, above, below
                    label4: "",
                    labelDisplay4: "default",    // default, custom
                    floatingLabel4: "State",
                    placeHolder4: "",

                    style5: "outlined",          // outlined, standard, filled
                    size5: "small",             // small, medium
                    widthType5: 'percentage',   // percentage, pixels
                    widthEntry5: "60",          // numeric width
                    width5: "60%",             // numeric width + "px"
                    max5: "10",
                    labelPosition5: "none",      // none, above, below
                    label5: "",
                    labelDisplay5: "default",    // default, custom
                    floatingLabel5: "Zip",
                    placeHolder5: "",
                };
                break;
            case questionTypes.STATE_ONLY:
                tempObj = {
                    questionType: this.props.questionType,
                };
                break;
            case questionTypes.YES_NO:
            case questionTypes.RADIO:
            case questionTypes.LIKERT_RADIO:
                tempObj = {
                    questionType: this.props.questionType,
                    displayAs: "single",        // single, likert
                    style: "radios",            // radios, ddlb, standard, clear, outlined
                    likertStyle: "radios",      // radios, buttongroup (squares), circle
                    circleType: "standard",     // standard, primary, alternate
                    alignAnswersRight: false,
                    alignQuestionsAndAnswers: false,
                    showSelectedLabel: "no",    //no, yes
                    title: "none",
                    titleType: "None",     // standard, graybar
                    titleLeft: "",
                    titleCenter: "",
                    titleRight: "",
                    indentEntry: 0,
                    indent: "",
                    arrange: "row",             // row, side, wrap
                    state: "",
                    property: "",
                    color: "default",           // default, custom
                    selectedBorder: {
                        backgroundColor: "",
                        backgroundImage: "",
                        colorType: "default",
                        colorIndex: null,
                        colorEntry: "black",
                        color: "black"
                    },
                    selectedBackground: {
                        backgroundColor: "",
                        backgroundImage: "",
                        colorType: "default",
                        colorIndex: null,
                        colorEntry: "black",
                        color: "black"
                    },
                    selectedControl: {
                        backgroundColor: "",
                        backgroundImage: "",
                        colorType: "default",
                        colorIndex: null,
                        colorEntry: "black",
                        color: "black"
                    },
                    selectedText: {
                        backgroundColor: "",
                        backgroundImage: "",
                        colorType: "default",
                        colorIndex: null,
                        colorEntry: "black",
                        color: "black"
                    },
                    unselectedBorder: {
                        backgroundColor: "",
                        backgroundImage: "",
                        colorType: "default",
                        colorIndex: null,
                        colorEntry: "black",
                        color: "black"
                    },
                    unselectedBackground: {
                        backgroundColor: "",
                        backgroundImage: "",
                        colorType: "default",
                        colorIndex: null,
                        colorEntry: "black",
                        color: "black"
                    },
                    unselectedControl: {
                        backgroundColor: "",
                        backgroundImage: "",
                        colorType: "default",
                        colorIndex: null,
                        colorEntry: "black",
                        color: "black"
                    },
                    unselectedText: {
                        backgroundColor: "",
                        backgroundImage: "",
                        colorType: "default",
                        colorIndex: null,
                        colorEntry: "black",
                        color: "black"
                    },
                };
                break;
            case questionTypes.CHECKBOX:

                tempObj = {
                    questionType: this.props.questionType,
                    style: "checkboxes",            // checkboxes, outlined
                    arrange: "row",             // row, side, wrap
                    color: "default",           // default, custom
                    selectedBorder: {
                        backgroundColor: "",
                        backgroundImage: "",
                        colorType: "default",
                        colorIndex: null,
                        colorEntry: "black",
                        color: "black"
                    },
                    selectedBackground: {
                        backgroundColor: "",
                        backgroundImage: "",
                        colorType: "default",
                        colorIndex: null,
                        colorEntry: "black",
                        color: "black"
                    },
                    selectedControl: {
                        backgroundColor: "",
                        backgroundImage: "",
                        colorType: "default",
                        colorIndex: null,
                        colorEntry: "black",
                        color: "black"
                    },
                    selectedText: {
                        backgroundColor: "",
                        backgroundImage: "",
                        colorType: "default",
                        colorIndex: null,
                        colorEntry: "black",
                        color: "black"
                    },
                    unselectedBorder: {
                        backgroundColor: "",
                        backgroundImage: "",
                        colorType: "default",
                        colorIndex: null,
                        colorEntry: "black",
                        color: "black"
                    },
                    unselectedBackground: {
                        backgroundColor: "",
                        backgroundImage: "",
                        colorType: "default",
                        colorIndex: null,
                        colorEntry: "black",
                        color: "black"
                    },
                    unselectedControl: {
                        backgroundColor: "",
                        backgroundImage: "",
                        colorType: "default",
                        colorIndex: null,
                        colorEntry: "black",
                        color: "black"
                    },
                    unselectedText: {
                        backgroundColor: "",
                        backgroundImage: "",
                        colorType: "default",
                        colorIndex: null,
                        colorEntry: "black",
                        color: "black"
                    },
                };
                break;
            // case questionTypes.LIKERT_RADIO:
            //     tempObj = {
            //         questionType: this.props.questionType,
            //     };
            //     break;
            case questionTypes.DATE_RANGE:
                tempObj = {
                    questionType: this.props.questionType,

                    arrange: "row",
                    style: "outlined",          // outlined, standard, filled
                    size: "small",             // small, medium
                    widthType: 'percentage',   // percentage, pixels
                    widthEntry: "100",          // numeric width
                    width: "100%",             // numeric width + "px"
                    max: "255",
                    labelPosition: "none",      // none, above, below
                    label: "",
                    labelDisplay: "default",    // default, custom
                    floatingLabel: "From Date",
                    placeHolder: "",

                    style2: "outlined",          // outlined, standard, filled
                    size2: "small",             // small, medium
                    widthType2: 'percentage',   // percentage, pixels
                    widthEntry2: "100",          // numeric width
                    width2: "100%",             // numeric width + "px"
                    max2: "255",
                    labelPosition2: "none",      // none, above, below
                    label2: "",
                    labelDisplay2: "default",    // default, custom
                    floatingLabel2: "To Date",
                    placeHolder2: ""
                };
                break;

            // no default
        }

        let mpo = this.props.metadataPropsObj;

        this.setState({[fieldName]: selected}, () => {mpo.setCustomMetadataFunction(mpo.template, mpo.templateUpdateFunction, mpo.setStateCallbackFunction, this.props.fieldName, selected, mpo.metadataTag, tempObj, mpo.depth)});
    };


    render() {

        // const {
        // } = this.props;
        //
        // const {
        // } = this.state;

        return (
            <React.Fragment>
                {this.showFormatOption() ?
                    <React.Fragment>
                        <div style={{width: "15px"}}></div>

                        <ArgoTextFieldSelectHook
                            fileName="ArgoQuestionFormatDefinition"
                            fieldName="customFormat"
                            label="Portal Format"
                            value={this.state.customFormat}
                            onChange={this.onSelectChange}
                            width="130px"
                            menuItems={[{value: "default", text: "Default"}, {value: "custom", text: "Custom"}]}
                        />

                    </React.Fragment>
                    :
                    <div style={{width: "145px"}}></div>
                }
            </React.Fragment>
        );
    }
}

ArgoQuestionFormatDefinition.defaultProps = {
};

ArgoQuestionFormatDefinition.propTypes = {
    questionType: PropTypes.string,
    fieldName: PropTypes.string,
    initTo: PropTypes.string,
    metadataPropsObj: PropTypes.object
};

export default ArgoQuestionFormatDefinition;
