import React from "react";
import * as typo  from "../utilities/getCustomTypography";
import * as font from "../utilities/fontUtilities";
import * as tag from "../constants/customMetadataConstants";
import * as ddo from "../constants/customObjConstants";
import * as icon from "../constants/iconConstants";
//import * as mode from "./displayOneQuestionPerPage";


export function getLikertTitleObj(savedObjs, orderedAnswers, floatDirection, pyramidMode = false, LeftOnly, rightOnly) {

    let defaultWidthNum = 44;
    let defaultWidthHalfStr = (defaultWidthNum / 2) + "px";
    let defaultWidth1xStr = defaultWidthNum + "px";
    let defaultWidth2xStr = (defaultWidthNum * 2) + "px";
    let defaultWidth3xStr = (defaultWidthNum * 3) + "px";

    let titleObj = {
        likertTitleType: icon.LIKERT_ICON_TITLES_NONE, // By default titles are "hidden"
        embeddedTitles: false,
        useGraybarHeader: false,
        useStandardHeader: false,
        useAnswersHeader: false,
        hasTitle: false,
        leftTitle: "",
        centerTitle: "",
        rightTitle: "",
        defaultWidthNum: defaultWidthNum,
        defaultWidthHalfStr: defaultWidthHalfStr,
        defaultWidth1xStr: defaultWidth1xStr,
        defaultWidth2xStr: defaultWidth2xStr,
        defaultWidth3xStr: defaultWidth3xStr,
        unitPadWidth: "0px",
        initPadWidth: "0px",
        leftWidth: "0px",
        centerWidth: "0px",
        rightWidth: "0px",
        htmlTitleDiv: "",
        //getTitle: function () {return this.htmlTitleDiv}
    };

    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    if (orderedAnswers.length === 0) {
        return titleObj; // NOTE: If no answers then do NOT render titles
    }

    if (!savedObjs.isFirstQuestionOfGroupLikert) {
        return titleObj;  // NOTE: Bail out if NOT first question of a group likert
    }
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    // -----------------------------------------------------------------------------------------------------------------
    // Suppress titles in a likert group for all but the first question
    // if (savedObjs.isGroupLikert && !savedObjs.isFirstQuestionOfGroupLikert) {
    //     return titleObj;
    // }
    // -----------------------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------------------
    // Check for sepcific title selections
    // LIKERT_ICON_TITLES_ALL = "As title above";  // Thumbs, Stars and Faces
    // LIKERT_ICON_TITLES_LR = "As title above (LR) only";  // Stars and Faces
    // LIKERT_ICON_TITLES_LCR = "As title above (LCR) only)";  // Stars and Faces with odd number of answers
    // LIKERT_ICON_TITLES_NONE = "Hidden";  // Thumbs, Stars and Faces
    // LIKERT_ICON_TITLES_EMBEDDED = "Embedded";  // Circles and Squares Answer Text 1 or 2 characters embedded in icon


    // See if any of the answers have ICON_TITLE set to "hidden" ie.. icon.LIKERT_ICON_TITLES_NONE
    for (let i = 0; i < orderedAnswers.length; i++) {
        if (tag.metadataExists(orderedAnswers[i], icon.ICON_TITLES)) {
            titleObj.likertTitleType = tag.metadataValue(orderedAnswers[i], icon.ICON_TITLES);
            if (titleObj.likertTitleType === icon.LIKERT_ICON_TITLES_EMBEDDED) {
                titleObj.embeddedTitles = true;
                titleObj.likertTitleType = icon.LIKERT_ICON_TITLES_NONE;  // If titles are embbedded then set back to hidden
            }
            break;
        }
    }

    // If radios and any answers do not have HIDE_BUTTONGROUP_NAME then show answerText above each radio
    let radioShowAnswerTextAsTitle = false;
    if (titleObj.likertTitleType === icon.LIKERT_ICON_TITLES_ALL) {
        radioShowAnswerTextAsTitle = true;

        for (let i = 0; i < orderedAnswers.length; i++) {
            if (tag.metadataExists(orderedAnswers[i], tag.HIDE_BUTTONGROUP_NAME)) {
                radioShowAnswerTextAsTitle = false;
                break;
            }
        }
    }




    // check for graybar
    // standard
    // answers as titles (radios and circles only)


    // -----------------------------------------------------------------------------------------------------------------
    // If radios and any answers do not have HIDE_BUTTONGROUP_NAME then show answerText above each radio
    // This takes priority over having titles set
    // let type = icon.TYPE_RADIO;
    // if (savedObjs.answers !== null) {
    //     type = tag.metadataValue(savedObjs.answers[0], icon.ICON_TYPE, "", icon.TYPE_RADIO);  // TODO need way to determine what type of input object we are dealing with question.metadata.likertStyles = "radios"
    // }

    //let rightAndAlignedWithQuestions = savedObjs.rightAlignLikertAnswers && savedObjs.alignQuestionsWithAnswers;

    titleObj.useAnswersHeader = (tag.metadataExists(savedObjs.activeTemplate, tag.USE_LIKERT_ANSWERS_HEADER));

    // titleObj.leftTitle = tag.metadataValue(savedObjs.activeTemplate, tag.LIKERT_HEADER_TITLE_LEFT);
    // titleObj.centerTitle = tag.metadataValue(savedObjs.activeTemplate, tag.LIKERT_HEADER_TITLE_CENTER);
    // titleObj.rightTitle = tag.metadataValue(savedObjs.activeTemplate, tag.LIKERT_HEADER_TITLE_RIGHT);

    titleObj.leftTitle = savedObjs.customTemplateObj.titleLeft;
    titleObj.centerTitle = savedObjs.customTemplateObj.titleCenter;
    titleObj.rightTitle = savedObjs.customTemplateObj.titleRight;

    titleObj.hasTitle = !!titleObj.leftTitle || !!titleObj.centerTitle || !!titleObj.rightTitle;

    // If right aligned flip left and right title
    if (savedObjs.rightAlignLikertAnswers) {
        let saveLeftTitle = titleObj.leftTitle;
        titleObj.leftTitle = titleObj.rightTitle;
        titleObj.rightTitle = saveLeftTitle;
    }


    // These answers are already flipped becasue they come from an ordered array
    // If no declared titles then check for declarations in answers
    if (!titleObj.hasTitle) {

        if (titleObj.likertTitleType === icon.LIKERT_ICON_TITLES_LR) {
            titleObj.hasTitle = true;
            titleObj.leftTitle = orderedAnswers[0].optionText;
            titleObj.rightTitle = orderedAnswers[orderedAnswers.length - 1].optionText;
        }
        if (titleObj.likertTitleType === icon.LIKERT_ICON_TITLES_LCR) {
            titleObj.hasTitle = true;
            titleObj.leftTitle = orderedAnswers[0].optionText;
            //tempCenterTitle = orderedAnswers[Math.floor(orderedAnswers.length / 2)].optionText;
            titleObj.rightTitle = orderedAnswers[orderedAnswers.length - 1].optionText;
        }
    }

    // In case were in pyramid mode center/right titles are ignored and handeled by the getPyramid() function
    let tempLeftTitle = rightOnly ? "" : titleObj.leftTitle;
    let tempCenterTitle = (LeftOnly || rightOnly) ? "" : titleObj.centerTitle;
    let tempRightTitle = LeftOnly ? "" : titleObj.rightTitle;


    // If there are titles entered are the standard or graybar

    // else {
    //     return titleObj;  // !!!!!! bail out no titles !!!!!
    // }


    // label bar logic for both group and question level using graybar and standard labels, for all alignment possibilities
    //let useLabelBar = hasTitle && (((useGrayBarQuestionLevel || useStandardBarQuestionLevel) && (!rightAndAlignedWithQuestions || savedObjs.rightAlignLikertAnswers)) || (useStandardBarGroupLevel && !rightAndAlignedWithQuestions));


    //let orderedAnswers = answers;

    // IF asligned right reverse titles
    // if (!isLeftAligned) {
    //     orderedAnswers = answers.map(answer => answer).reverse();  // Reverse answers so fill from right to left for right alligned answers options
    // }




    let customTextObj = getLikertTitleLabelProperties(savedObjs.activeTemplate);  // Check metadata for custom title overrides

    if ((titleObj.likertTitleType === icon.LIKERT_ICON_TITLES_NONE)&&(!titleObj.hasTitle)) {
        return titleObj;  // Bail out title is hidden
    }

    if (titleObj.likertTitleType === icon.LIKERT_ICON_TITLES_ALL) {

        // let radioShowAnswerTextAsTitle = false;  ZZZZZZ Seemed duplicate code
        // if (titleObj.likertTitleType === icon.LIKERT_ICON_TITLES_ALL) {
        //     radioShowAnswerTextAsTitle = true;
        //
        //     for (let i = 0; i < orderedAnswers.length; i++) {
        //         if (tag.metadataExists(orderedAnswers[i], tag.HIDE_BUTTONGROUP_NAME)) {
        //             radioShowAnswerTextAsTitle = false;
        //             break;
        //         }
        //     }
        // }

        if (radioShowAnswerTextAsTitle) {

            let tempStyles = {
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            };

            let CustomTypography = typo.getCustomTypography(customTextObj, "center", "caption");

            titleObj.htmlTitleDiv = <div style={{width: "100%"}}>
                <div key={"RadioTitles1-" + savedObjs.activeTemplate.id} style={{float: floatDirection, width: "100%"}}>
                    <div key={"RadioTitles2-" + savedObjs.activeTemplate.id} style={{
                        float: floatDirection,
                        width: titleObj.defaultWidth1xStr,
                        ...tempStyles
                    }}>&nbsp;</div>
                    {orderedAnswers.map((answer, index) =>
                        <div key={"OrderedAnswer-" + index + "-" + answer.id} style={{
                            float: floatDirection,
                            width: titleObj.defaultWidth1xStr,
                            ...tempStyles
                        }}>{<CustomTypography>{answer.optionText}</CustomTypography>}</div>
                    )}
                    <div key={"RadioTitles3-" + savedObjs.activeTemplate.id} style={{
                        float: floatDirection,
                        width: titleObj.defaultWidth1xStr,
                        ...tempStyles
                    }}>&nbsp;</div>
                </div>
            </div>
        }

    }
    else {

        // Calc DIV widths for title text
        if (titleObj.hasTitle) {
            // See if there are 3 titles
            switch (savedObjs.answers.length.toString()) {
                case "2":
                    // No center title allowed
                    titleObj.unitPadWidth = titleObj.defaultWidth1xStr;
                    titleObj.leftWidth = titleObj.defaultWidth1xStr;
                    titleObj.rightWidth = titleObj.defaultWidth1xStr;
                    break;
                case "3":
                    if (tempCenterTitle) {
                        titleObj.leftWidth = titleObj.defaultWidth1xStr;
                        titleObj.centerWidth = titleObj.defaultWidth1xStr;
                        titleObj.rightWidth = titleObj.defaultWidth1xStr;
                    } else // Just left and right titles
                    {
                        titleObj.unitPadWidth = titleObj.defaultWidthHalfStr;
                        titleObj.leftWidth = titleObj.defaultWidth2xStr;
                        titleObj.rightWidth = titleObj.defaultWidth2xStr;
                    }
                    break;
                case "4":
                    // No center allowed
                    titleObj.unitPadWidth = titleObj.defaultWidthHalfStr;
                    titleObj.leftWidth = titleObj.defaultWidth2xStr;
                    titleObj.centerWidth = titleObj.defaultWidth1xStr;
                    titleObj.rightWidth = titleObj.defaultWidth2xStr;
                    break;
                case "5":
                    titleObj.unitPadWidth = titleObj.defaultWidthHalfStr;
                    titleObj.leftWidth = titleObj.defaultWidth2xStr;
                    titleObj.centerWidth = titleObj.defaultWidth2xStr;
                    titleObj.rightWidth = titleObj.defaultWidth2xStr;
                    break;
                case "6":
                    titleObj.leftWidth = titleObj.defaultWidth3xStr;
                    titleObj.centerWidth = titleObj.defaultWidth2xStr;
                    titleObj.rightWidth = titleObj.defaultWidth3xStr;
                    break;
                case "7":
                    titleObj.leftWidth = titleObj.defaultWidth3xStr;
                    titleObj.centerWidth = titleObj.defaultWidth3xStr;
                    titleObj.rightWidth = titleObj.defaultWidth3xStr;
                    break;
                case "10":
                    titleObj.leftWidth = titleObj.defaultWidth3xStr;
                    titleObj.centerWidth = (titleObj.defaultWidthNum * 6) + "px";
                    titleObj.rightWidth = titleObj.defaultWidth3xStr;
                    break;
                case "11":
                    if (pyramidMode) {
                        if (LeftOnly) {
                            titleObj.leftWidth = "160px";
                            titleObj.centerWidth = "0px";
                            titleObj.rightWidth = "0px";
                        }
                        else if (rightOnly) {
                            titleObj.leftWidth = "235px";
                            titleObj.centerWidth = "0px";
                            titleObj.rightWidth = "150px";
                        }
                    }
                    else {
                        titleObj.leftWidth = titleObj.defaultWidth3xStr;
                        titleObj.centerWidth = (titleObj.defaultWidthNum * 7) + "px";
                        titleObj.rightWidth = titleObj.defaultWidth3xStr;
                    }
                    break;
                // no default
            }

        }

        if (titleObj.hasTitle) {
            // titleObj.useGraybarHeader = (tag.metadataExists(savedObjs.activeTemplate, tag.USE_LIKERT_GRAYBAR_HEADER));
            // titleObj.useStandardHeader = (tag.metadataExists(savedObjs.activeTemplate, tag.USE_LIKERT_STANDARD_HEADER));

            titleObj.useGraybarHeader = (savedObjs.customTemplateObj.titleType === "Graybar");
            titleObj.useStandardHeader = (savedObjs.customTemplateObj.titleType === "Standard");
        }

        let CustomTypographyLeft = typo.getCustomTypography(customTextObj, "center", "caption");

        let CustomTypographyCenter = typo.getCustomTypography(customTextObj, "center", "caption");

        // If right only is true, then this is a pyramid and the title needs adjustments
        let CustomTypographyRight = typo.getCustomTypography(customTextObj, "center", "caption", rightOnly);

        let moreStyles = {
            //backgroundColor: titleObj.useGraybarHeader ? graybarColor : "white", // cant use gradients because its different divs
            //backgroundImage = graybarColor;
            paddingLeft: pyramidMode ? "7px" : "0px",
            display: "flex",
            justifyContent: pyramidMode ? "left" : "center",
            alignItems: pyramidMode ? "left" : "center",
        }

        if (titleObj.useGraybarHeader) {
            let graybarColor = tag.metadataValue(savedObjs.activeTemplate, tag.USE_LIKERT_GRAYBAR_COLOR, "", tag.DEFAULT_GRAYBAR_COLOR);

            moreStyles.backgroundColor = titleObj.useGraybarHeader ? graybarColor : "white"; // cant use gradients because its different divs

            moreStyles.height = tag.metadataValue(savedObjs.activeTemplate, tag.USE_LIKERT_GRAYBAR_HEIGHT, "", tag.DEFAULT_GRAYBAR_HEIGHT);
        }

        titleObj.htmlTitleDiv = <div style={{width: "100%"}}>
            <div key={"Title1-" + savedObjs.activeTemplate.id} style={{float: floatDirection, width: "100%"}}>
                <div  key={"Title2-" + savedObjs.activeTemplate.id} style={{float: floatDirection, width: titleObj.unitPadWidth, ...moreStyles}}>&nbsp;</div>
                <div  key={"Title3-" + savedObjs.activeTemplate.id} style={{
                    float: floatDirection,
                    width: titleObj.leftWidth,
                    ...moreStyles}}>{
                    <CustomTypographyLeft>{tempLeftTitle === "" ? '\u00A0' : tempLeftTitle}</CustomTypographyLeft>
                }</div>
                <div  key={"Title4-" + savedObjs.activeTemplate.id} style={{
                    float: floatDirection,
                    width: titleObj.centerWidth,
                    ...moreStyles
                }}>{
                    <CustomTypographyCenter>{tempCenterTitle === "" ? '\u00A0' : tempCenterTitle}</CustomTypographyCenter>
                }</div>
                <div  key={"Title5-" + savedObjs.activeTemplate.id} style={{
                    float: floatDirection,
                    width: titleObj.rightWidth,
                    borderRadius: titleObj.useGraybarHeader ? (savedObjs.rightAlignLikertAnswers ? '100px 0 0 100px' : '0 100px 100px 0') : "",
                    ...moreStyles
                }}>{<CustomTypographyRight>{tempRightTitle === "" ? '\u00A0' : tempRightTitle}</CustomTypographyRight>}</div>
            </div>
        </div>

    }

    return titleObj;
}


export function getLikertTitleLabelProperties(question) {

    let textStyleObj = font.getDefaultOrCustomTextObj(question, ddo.OBJ_TYPE.TEXT, ddo.OBJ_TEXT_TYPE.NAME, "title");


    let likertTitleLabelSize = "12pt";
    let likertTitleLabelColor = "black";
    let likertTitleLabelAlign = "center";
    let likertTitleLabelWeight = "normal";
    let likertTitleLabelStyle = "normal";
    let likertTitleLabelOpacity = "100%";
    let likertTitleLabelFont = font.DEFAULT_FONT_FAMILY;
    let likertTitlesObj = null;


    if (textStyleObj.type === "default") {

        if (tag.metadataExists(question, tag.DISPLAY_LIKERT_TITLE_SIZE)) {
            likertTitleLabelSize = (tag.metadataValue(question, tag.DISPLAY_LIKERT_TITLE_SIZE));
        }

        if (tag.metadataExists(question, tag.DISPLAY_LIKERT_TITLE_COLOR)) {
            likertTitleLabelColor = (tag.metadataValue(question, tag.DISPLAY_LIKERT_TITLE_COLOR));
        }

        if (tag.metadataExists(question, tag.DISPLAY_LIKERT_TITLE_ALIGN)) {
            likertTitleLabelAlign = (tag.metadataValue(question, tag.DISPLAY_LIKERT_TITLE_ALIGN));
        }

        if (tag.metadataExists(question, tag.DISPLAY_LIKERT_TITLE_WEIGHT)) {
            likertTitleLabelWeight = (tag.metadataValue(question, tag.DISPLAY_LIKERT_TITLE_WEIGHT));
        }

        if (tag.metadataExists(question, tag.DISPLAY_LIKERT_TITLE_STYLE)) {
            likertTitleLabelStyle = (tag.metadataValue(question, tag.DISPLAY_LIKERT_TITLE_STYLE));
        }

        if (tag.metadataExists(question, tag.DISPLAY_LIKERT_TITLE_OPACITY)) {
            likertTitleLabelOpacity = (tag.metadataValue(question, tag.DISPLAY_LIKERT_TITLE_OPACITY));
        }

        if (tag.metadataExists(question, tag.DISPLAY_LIKERT_TITLE_FONT)) {
            likertTitleLabelFont = (tag.metadataValue(question, tag.DISPLAY_LIKERT_TITLE_FONT));
        }

        likertTitlesObj = {
            width: '100%',
            fontFamily: likertTitleLabelFont,
            fontSize: likertTitleLabelSize,
            textAlign: likertTitleLabelAlign,
            colorType: "select",
            color: likertTitleLabelColor,
            colorEntry: likertTitleLabelColor,
            fontWeight: likertTitleLabelWeight,
            fontStyle: likertTitleLabelStyle,
            opacity: likertTitleLabelOpacity,
        }

    }
    else {

        likertTitlesObj = {
            width: '100%',
            fontFamily: textStyleObj.fontObj.fontFamily,
            fontSize: textStyleObj.fontObj.fontSize,
            textAlign: likertTitleLabelAlign,
            color: textStyleObj.fontObj.colorType === "select" ? textStyleObj.fontObj.color : textStyleObj.fontObj.colorEntry,
            colorEntry: textStyleObj.fontObj.colorEntry,
            fontWeight: textStyleObj.fontObj.fontWeight,
            fontStyle: textStyleObj.fontObj.fontStyle,
            opacity: textStyleObj.fontObj.opacity,
        }

    }

    return likertTitlesObj;
}
