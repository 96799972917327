import React, {Component} from 'react';
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from 'prop-types';
import {getQuestionnaireTemplate,
    createQuestionnaireTemplateAndCloseDrawer,
    saveQuestionnaireTemplateAndCloseDrawer,
    cloneQuestionnaireTemplateAndCloseDrawer,
    questionnaireTemplateInit,
    questionnaireTemplateClone} from "../../../actions/questionnaireTemplateActions";
import {openDrawer} from "../../../actions/drawerActions";
import {notificationOpen} from "../../../actions/notificationActions";
import QuestionnaireEditor from "./QuestionnaireEditor";
// import QuestionnaireReviewDrawer, {questionnaireReviewDrawerProps} from "../../drawers/QuestionnaireReviewDrawer";
// import QuestionnairePublishOrChangeDateDrawer, {questionnairePublishOrChangeDateDrawerProps} from "../../drawers/QuestionnairePublishOrChangeDateDrawer";
import UserContext from "../../../UserContext";
import {QD_URL} from "../../../constants/systemConstants";
import * as TEMPLATE from "../../../constants/templateConstants";


class QuestionnaireContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            templateKey: "",
            createMode: true,
            cloneMode: false,
            saveToolbarButtonDisabled: true,
            toolbarErrorText: ""
        };
    }

    static contextType = UserContext;  // From blueTheme.js

    componentDidMount() {

        const currURL = window.location.href;
        const objURL = new URL(currURL);

        let pathnameArray = objURL.pathname.split("/");

        let createMode = (pathnameArray[3] === ""); // Must be in create mode
        let templateKey = pathnameArray[3];
        let templateId = createMode ? null : parseInt(pathnameArray[4]);
        let cloneMode = createMode ? false : (pathnameArray[5] === "1"); // Clone mode "0" = false, "1" = true

        if (!createMode) {
            this.props.getQuestionnaireTemplate(templateKey, templateId);
        }

        this.setState({templateKey, templateId, createMode, cloneMode});
    }

    // openQuestionnaireReviewDrawer = () => {
    //     let template = this.props.templateEditor;
    //
    //     this.props.openDrawer(questionnaireReviewDrawerProps, <QuestionnaireReviewDrawer templateKey={template.templateKey} templateId={template.ID}/>);
    // };

    saveClone = () => {
        if (this.props.options.hasError) {
            this.props.notificationOpen("Validate fields before saving.");
            return;
        }

        let updatedTemplate = {
            ...this.props.templateEditor,
            categoryKeys: this.props.categoryKeysEditor
        };

        // Don't clone any published data
        updatedTemplate.startDate = null;
        updatedTemplate.endDate = null;
        updatedTemplate.notes = "";

        this.props.questionnaireTemplateClone();
        this.props.cloneQuestionnaireTemplateAndCloseDrawer(updatedTemplate, this.props.filter, this.props.versionsFilter, TEMPLATE.MODE.CURRENT, this.navigateToQuestionnaireList);
    };

    save = () => {
        this.props.questionnaireTemplateInit();
        if (this.props.options.hasError) {
            this.props.notificationOpen("Validate fields before saving.");
            return;
        }

        let updatedTemplate = {
            ...this.props.templateEditor,
            categoryKeys: this.props.categoryKeysEditor,
        };

        // -------------------------------------------------------------------------------------------------------------

        if (this.state.createMode) {
            updatedTemplate.questionnaireType = this.props.questionnaireType;

            this.props.createQuestionnaireTemplateAndCloseDrawer(updatedTemplate, this.props.filter, this.navigateToQuestionnaireList);
        }
        else {
            this.props.saveQuestionnaireTemplateAndCloseDrawer(updatedTemplate, this.props.filter, this.props.versionsFilter, TEMPLATE.MODE.CURRENT, this.navigateToQuestionnaireList);
        }
    };


    // saveAndPublish = (event, changeDTO) => {
    //     this.props.questionnaireTemplateInit();
    //     this.save(null, changeDTO);
    // };

    // openSaveAndPublish = () => {
    //     if (this.props.options.hasError) {
    //         this.props.notificationOpen("Validate fields before saving.");
    //         return;
    //     }
    //
    //     let templateKey = Object.keys(this.props.template)[0];
    //
    //     this.props.openDrawer(questionnairePublishOrChangeDateDrawerProps, <QuestionnairePublishOrChangeDateDrawer
    //         title={"Publish " + this.props.templateEditor.name}
    //         buttonLabel="PUBLISH"
    //         template={this.props.templateEditor}
    //         currentStartDate={templateKey !== null ? (this.props.template[templateKey] !== undefined ? this.props.template[templateKey].startDate : "") : ""}
    //         action={this.saveAndPublish}
    //     />);
    // };

    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {
        switch (buttonTitle) {
            case "SAVE":
                if (this.state.cloneMode) {
                    this.saveClone();
                }
                else {
                    this.save();
                }
                break;
            case "CANCEL":
                this.navigateToQuestionnaireList();
                break;
            // no default
        }
    };

    navigateToQuestionnaireList = () => {
        this.props.navHook(QD_URL + "/questionnaires", {replace: true});
    };


    render() {

        // let displayName = "";
        // if (!this.state.templateKey || this.props.templateEditor.templateKey === this.state.templateKey) {
        //     displayName = this.props.templateEditor.name;
        // } else if (this.props.template[this.state.templateKey]) {
        //     displayName = this.props.template[this.state.templateKey].name;
        // }

        return (
            <div id="QuestionnaireContainer-main-div">

                <div id="QuestionnaireContainer-container-div" style={{padding: "0px 0px", overflow: "visible", marginTop: "64px"}}>

                    <QuestionnaireEditor
                        createMode={this.props.createMode}
                        templateKey={this.state.templateKey}
                        templateId={this.state.templateId}
                        cloneMode={this.state.cloneMode}
                        width="100%"
                        handleToolbarButtonClick={this.handleToolbarButtonClick}
                    />

                </div>
            </div>
        );
    }
}

QuestionnaireContainer.defaultProps = {
    createMode: false,
    match: {},
    questionnaireTemplateActions: {},
    templateKey: "",
    template: undefined
};

QuestionnaireContainer.propTypes = {
    createMode: PropTypes.bool,
    templateKey: PropTypes.string,
    templateId: PropTypes.number,
    match: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getQuestionnaireTemplate,
        createQuestionnaireTemplateAndCloseDrawer,
        saveQuestionnaireTemplateAndCloseDrawer,
        cloneQuestionnaireTemplateAndCloseDrawer,
        openDrawer,
        notificationOpen,
        questionnaireTemplateInit,
        questionnaireTemplateClone
    }, dispatch);
}

function mapStateToProps(state) {

    return {
        templateEditor: state.questionnaireEditor.template,
        categoryKeysEditor: state.questionnaireEditor.categoryKeys,
        options: state.questionnaireEditor.options,
        questionnaireType: state.questionnaireEditor.questionnaireType,
        template: state.questionnaireTemplate,
        filter: state.filter[TEMPLATE.FILTER.current.questionnaire],
        versionsFilter: state.filter[TEMPLATE.FILTER.versions.questionnaire]
    };
}

function myParams(Component) {
    return props => <Component {...props} navHook={useNavigate()} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(myParams(QuestionnaireContainer));
