import * as tag from "./customMetadataConstants";
import * as ssType from "./singleSelectTypes";


export function singleSelectDisplayAs(question, answers) {

    let singleSelectDisplayAs = ssType.SINGLE_SELECT_RADIO;  // Default to radio buttons

    // Override order, if multiple overrides are entered this is the order of priority
    if (tag.metadataExists(question, tag.DISPLAY_SINGLE_SELECT_AS_RADIOS)) {
        singleSelectDisplayAs = ssType.SINGLE_SELECT_RADIO;
    }
    else if (tag.metadataExists(question, tag.DISPLAY_SINGLE_SELECT_AS_DDLB)) {
        singleSelectDisplayAs = ssType.SINGLE_SELECT_DDLB;
    }
    else if (tag.metadataExists(question, tag.DISPLAY_SINGLE_SELECT_AS_STANDARD_BUTTONS)) {
        singleSelectDisplayAs = ssType.SINGLE_SELECT_STANDARD_BUTTON;
    }
    else if (tag.metadataExists(question, tag.DISPLAY_SINGLE_SELECT_AS_CLEAR_BUTTONS)) {
        singleSelectDisplayAs = ssType.SINGLE_SELECT_CLEAR_BUTTON;
    }
    else if (tag.metadataExists(question, tag.DISPLAY_SINGLE_SELECT_AS_OUTLINED_RADIOS)) {
        singleSelectDisplayAs = ssType.SINGLE_SELECT_OUTLINED_RADIO;
    }
    else {
        // If no overrides then default behaviour is radio button if < 6 answers, otherwise DDLB
        if (answers.length < 6) {
            singleSelectDisplayAs = ssType.SINGLE_SELECT_RADIO;
        }
        else {
            singleSelectDisplayAs = ssType.SINGLE_SELECT_DDLB;
        }
    }

    return singleSelectDisplayAs;
}

// Metadata is stored in question template to override display mode BUTTONS (default)
// export function questionSingleSelectDisplayAs(obj) {
//
//     let displayAs = "";
//
//     let metadata = obj.metadata.metadataItems !== undefined ? obj.metadata.metadataItems : obj.metadata;
//
//     // Check for new portal metadata tags
//     if (displayAs === "") {
//         if (tag.metadataExists(obj, tag.DISPLAY_SINGLE_SELECT_AS_RADIOS)) {
//             displayAs = DISPLAY_AS_RADIOS;
//         }
//         if (tag.metadataExists(obj, tag.DISPLAY_SINGLE_SELECT_AS_DDLB)) {
//             displayAs = DISPLAY_AS_SELECT;
//         }
//         if (tag.metadataExists(obj, tag.DISPLAY_SINGLE_SELECT_AS_STANDARD_BUTTONS)) {
//             displayAs = DISPLAY_AS_BUTTONS;
//         }
//         if (tag.metadataExists(obj, tag.DISPLAY_SINGLE_SELECT_AS_BUTTONGROUP)) {
//             displayAs = DISPLAY_AS_BUTTON_GROUP;
//         }
//         if (tag.metadataExists(obj, tag.DISPLAY_BUTTONS_AS_CIRCLES)) {
//             displayAs = DISPLAY_AS_BUTTON_GROUP;
//         }
//         if (tag.metadataExists(obj, tag.DISPLAY_BUTTONS_AS_CIRCLES_COLORS)) {
//             displayAs = DISPLAY_AS_BUTTON_GROUP;
//         }
//         if (tag.metadataExists(obj, tag.DISPLAY_BUTTONS_AS_CIRCLES_COLORS_ALT)) {
//             displayAs = DISPLAY_AS_BUTTON_GROUP;
//         }
//         if (tag.metadataExists(obj, tag.DISPLAY_BUTTONS_AS_CIRCLES_PYRAMID)) {
//             displayAs = DISPLAY_AS_BUTTON_GROUP;
//         }
//         if (tag.metadataExists(obj, tag.DISPLAY_BUTTONS_AS_CIRCLES_PYRAMID_COLORS)) {
//             displayAs = DISPLAY_AS_BUTTON_GROUP;
//         }
//         if (tag.metadataExists(obj, tag.DISPLAY_BUTTONS_AS_CIRCLES_PYRAMID_COLORS_ALT)) {
//             displayAs = DISPLAY_AS_BUTTON_GROUP;
//         }
//     }
//
//     return displayAs;
// }



