

// Template Types
export const TYPE =
    {
        DEFAULT: "default",
        SELECT: "select",
        QUESTIONNAIRE_GRAPH: "questionnaire_graph", // Used by portal preview to distinguish between template mode and graph call
        QUESTIONNAIRE: "questionnaire",
        CATEGORY: "category",
        GROUP: "group",
        QUESTION: "question",
        ANSWER: "answer",
        ACTION: "action",
        TAG: "tag",                         // Not a template but behaves like one except no need for current or versions
        INTEGRATION: "integration",         // Not a template but behaves like one except no need for current or versions
        INTEGRATION_LOG: "integrationLog",    // Not a template but behaves like one except no need for current or versions
        SELECT_EXISTING_CATEGORY: "selectExistingCategory",
        SELECT_EXISTING_GROUP: "selectExistingGroup",
        SELECT_EXISTING_QUESTION: "selectExistingQuestion",
        SELECT_EXISTING_TAGS: "selectExistingTags",
    };


// Template Modes are used to determine which action to use
export const MODE =
    {
        ALL: "all",
        CURRENT: "current",
        VERSIONS: "versions"
    };


// Template Filter is used to determine the filter name in the index.js
export const FILTER =
    {
        // Tags/Integrations are not exactly templates but behave like them except there are no current/versions
        all: {
            tag: "tags",
            integration: "integrations",
            integrationLog: "integrationLogs",
            questionnaire: "questionnaires",  // Note: Keeping for future enhancement for DDLB data display w/filters
            category: "categories",
            group: "groups",
            question: "questions",
            action: "actions",
            default: "defaultfilter",
            select: "selectfilter",
            selectExistingTags: "selectExistingTagsFilter"
        },
        current: {
            questionnaire: "questionnaires",
            category: "categories",
            group: "groups",
            question: "questions",
            action: "actions",
            selectExistingCategory: "selectExistingCategoryFilter",
            selectExistingGroup: "selectExistingGroupFilter",
            selectExistingQuestion: "selectExistingQuestionFilter"
        },
        versions: {
            questionnaire: "questionnaireVersions",
            category: "categoryVersions",
            group: "groupVersions",
            question: "questionVersions",
            action: "actionVersions"
        }
    };

export const GRID_LIST_TYPE =
    {
        QUESTIONNAIRE_LIST: "QUESTIONNAIRE_LIST",
        QUESTIONNAIRE_VERSIONS_LIST: "QUESTIONNAIRE_VERSIONS_LIST",
        CATEGORY_LIST: "CATEGORY_LIST",
        CATEGORY_VERSIONS_LIST: "CATEGORY_VERSIONS_LIST",
        GROUP_LIST: "GROUP_LIST",
        GROUP_VERSIONS_LIST: "GROUP_VERSIONS_LIST",
        QUESTION_LIST: "QUESTION_LIST",
        QUESTION_VERSIONS_LIST: "QUESTION_VERSIONS_LIST",
        ACTION_LIST: "ACTION_LIST",
        ACTION_VERSIONS_LIST: "ACTION_VERSIONS_LIST",
        TAG_LIST: "TAG_LIST",
        INTEGRATION_LIST: "INTEGRATION_LIST",

    };


export const ACTION_TYPE =
    {
        INTEGRATION: "Integration",
        GOAL: "Goal",
        OTHER: "Other"  // NOTE: Note in database, need to add TODO
    };


// Template List is used to determine the list data name in the index.js
export const list =
    {
        questionnaire: "questionnaireTemplateList",
        category: "categoryTemplateList",
        group: "groupTemplateList",
        question: "questionTemplateList",
        action: "actionTemplateList",
        tag: "searchTagList",
        integration: "integrationList",
        integrationLog: "integrationLogsList",
    };


export const display =
    {
        questionnaire: "Questionnaire",
        category: "Category",
        group: "Group",
        question: "Question",
        action: "Action",
        tag: "Tag",                         // Not a template but behaves like one except no need for current or versions
        integration: "Integration",         // Not a template but behaves like one except no need for current or versions
        integrationLog: "Integration Log"    // Not a template but behaves like one except no need for current or versions
    };




