import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CommentIcon from '@mui/icons-material/Comment';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import {createTheme} from '@mui/material/styles';
import {ThemeProvider} from '@mui/material/styles';
import {styled} from "@mui/material/styles";
import PortalQuestion from "./PortalQuestion";
import {setTheme} from "./actions/themeActions";
import {answerQuestionOptions, answerQuestionTemplateOptions, answerQuestionText, answerQuestionTemplateText} from "./actions/portalActions";
import * as mode from "./utilities/displayOneQuestionPerPage";
import * as custom from "./utilities/getCustomTypography";
import {getCategoryTitleBar} from "./utilities/getCategoryTitleBar";
import * as font from "./utilities/fontUtilities";
import * as ddo from "./constants/customObjConstants";
import * as tag from "./constants/customMetadataConstants";
import green_checkmark from './constants/green_checkmark.png'


class PortalPageRenderer extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            initComponent: true
        };
    }

    navigateBack = () => {
        if (this.props.history) {
            this.props.history.push("/");
        }
    };

    // -----------------------------------------------------------------------------------------------------------------
    // Add  Page Object
    // -----------------------------------------------------------------------------------------------------------------

    getQnrObj = () => {

        let qnr = {};
        if (this.props.portalPreview) {
            qnr = (this.props.questionnaireTemplateFromStore !== null) ? this.props.questionnaireTemplateFromStore : this.props.questionnaireTemplate;
        }
        else {
            // On Portal Preview second load on the same screen we are getting a empty object {} this is to account for that!
            let tempQuestionnaireInstance = JSON.parse(JSON.stringify(this.props.questionnaireInstance));
            if (tempQuestionnaireInstance !== null) {
                let tempQuestionnaireInstanceString = JSON.stringify(tempQuestionnaireInstance);
                if (tempQuestionnaireInstanceString === "{}") {
                    tempQuestionnaireInstance = null;
                }
            }

            qnr = this.props.questionnaireInstance;
        }

        return qnr;
    }

    addSectionToPage = (sectionObj, pageNum, sectionNum, numberOfPages) => {

        // Get an array of item objects in this section
        let sectionItems = ddo.getObjValue(sectionObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM_ARRAY);

        // Convert the stored values to the actual text
        let justify = ddo.getObjValueWithConversion(sectionObj, ddo.OBJ.KEY.PAGE.SECTION.JUSTIFY);
        let alignItems = ddo.getObjValueWithConversion(sectionObj, ddo.OBJ.KEY.PAGE.SECTION.ALIGN_ITEMS);
        let classType = ddo.getObjValueWithConversion(sectionObj, ddo.OBJ.KEY.PAGE.SECTION.CLASS_TYPE);

        let qnr = this.getQnrObj(); // Get Questionnaire Obj (Instance or Template)

        let sectionClass = {padding: '8px 8px 8px 8px'}; // controlGrid
        if (classType === ddo.SECTION.CLASS_TYPE.FOOTER) {
            sectionClass = {padding: '10px 16px', borderTop: 'solid rgba(0, 0, 0, 0.1) 1px'}; // footerGrid
        }
        // switch (classType) {
        //     case ddo.SECTION.CLASS_TYPE.CONTROL:
        //         sectionClass = this.props.classes.controlGrid;
        //         break;
        //     case ddo.SECTION.CLASS_TYPE.FOOTER:
        //         sectionClass = this.props.classes.footerGrid;
        //         break;
        //     default:
        //         break;
        // }

        let divAndKeyName = "Section-" + pageNum + "-" + sectionNum;

        if (sectionItems[0].type === "LIKERT_GROUP") {

            // Fix for when create group likert but actual showGroup has not been created yet
            let questionsArray = sectionItems[0].questions;
            let showGroupKey = questionsArray.length ? ddo.showGroup(questionsArray[0].showGroupKey) : false;
            return (
                <div id={divAndKeyName} key={divAndKeyName} style={{display: showGroupKey ? "inline" : "none"}}>
                    <Grid
                        id={"OuterGridA-" + pageNum + "-" + sectionNum}
                        key={"Section-" + pageNum + "-" + sectionNum}
                        container
                        justifyContent={justify}
                        alignItems={alignItems}
                        style={{
                            ...sectionClass,
                            overflow: 'unset'
                        }}>
                        {sectionItems.map((item, index) => this.addItemToSection(qnr, item, pageNum, sectionNum, index, numberOfPages, sectionClass))}
                    </Grid>
                </div>
            );
        }
        else if (sectionItems[0].format === "TITLE_FORMAT_METADATA") {
            // This is for readonly mode to display metadata at top of survey
            // Reorder array so "Date Surveyed" is the Top Item
            let newSectionItems = [];
            let splitName = null;
            let dateIndex = 0;
            for (var i = 0; i < sectionItems.length; i++) {
                splitName = sectionItems[i].name.split("|");
                if (splitName[0] === "Date Surveyed") {
                    newSectionItems.push(sectionItems[i]);
                    dateIndex = i;
                    break;
                }
            }

            for (var ii = 0; ii < sectionItems.length; ii++) {
                splitName = sectionItems[ii].name.split("|");
                if (dateIndex !== ii) {
                    newSectionItems.push(sectionItems[ii]);
                }
            }

            return (
                <div id={divAndKeyName} key={divAndKeyName}>
                    <Grid
                        id={"OuterGridB-" + pageNum + "-" + sectionNum}
                        key={"Section-" + pageNum + "-" + sectionNum}
                        container
                        justifyContent={justify}
                        alignItems={alignItems}
                        style={{
                            ...sectionClass,
                            overflow: 'unset'
                        }}>
                        {newSectionItems.map((item, index) => this.addItemToSection(qnr, item, pageNum, sectionNum, index, numberOfPages, sectionClass))}
                    </Grid>
                </div>
            );
        }
        else {

            return (
                <div id={divAndKeyName} key={divAndKeyName} style={{display: ddo.showGroup(qnr, ddo.getObjValue(sectionItems[0], ddo.OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.SHOW_GROUP_KEY)) ? "inline" : "none"}}>
                    <Grid
                        id={"OuterGridC-" + pageNum + "-" + sectionNum}
                        key={"Section-" + pageNum + "-" + sectionNum}
                        container
                        justifyContent={justify}
                        alignItems={alignItems}
                        style={{
                            ...sectionClass,
                            overflow: 'unset'
                        }}>
                        {sectionItems.map((item, index) => this.addItemToSection(qnr, item, pageNum, sectionNum, index, numberOfPages, sectionClass))}
                    </Grid>
                </div>
            );
        }
    };


    getAriaLabelForButton = (btnMode, btnName) => {

        let ariaLabel = "";

        switch (btnMode) {
            case ddo.BUTTON.MODE.START:
                ariaLabel = btnName + ". Use this button to start the questionnaire";
                break;
            case ddo.BUTTON.MODE.CANCEL:
                ariaLabel = btnName + ". Use this button to cancel the questionnaire";
                break;
            case ddo.BUTTON.MODE.NEXT:
                ariaLabel = btnName + ". Use this button to navigate to the next page of the questionnaire";
                break;
            case ddo.BUTTON.MODE.PREVIOUS:
                ariaLabel = btnName + ". Use this button to navigate back one page of the questionnaire";
                break;
            case ddo.BUTTON.MODE.FINISH:
                ariaLabel = btnName + ". Use this button to submit your completed questionnaire";
                break;
            case ddo.BUTTON.MODE.CLOSE:
                ariaLabel = btnName + ". Use this button to close the questionnaire";
                break;
            case ddo.BUTTON.MODE.VIEW_PDF:
                ariaLabel = btnName + ". Use this button to view the questionnaire as PDF";
                break;
            default:
                break;
        }
        return ariaLabel;
    }


    addItemToSection  = (qnr, itemObj, pageNum, sectionNum, itemNum, numberOfPages, sectionClass) => {

        let itemType = ddo.getSectionItemType(itemObj);

        let currPageDisplayNumber = tag.metadataExists(qnr, tag.HAS_START_PAGE) ? pageNum : (pageNum + 1);
        let currPageDisplayOfNumber = numberOfPages;

        if (tag.metadataExists(qnr, tag.HAS_START_PAGE)) {
            currPageDisplayOfNumber -= 1;
        }
        if (!tag.metadataExists(qnr, tag.NO_CLOSE_PAGE)) {
            currPageDisplayOfNumber -= 1;
        }

        let detailsObj = ddo.convertTitleSourceReturnDetailsObj(qnr, itemObj, currPageDisplayNumber, currPageDisplayOfNumber, ("-p" + pageNum + "-s" + sectionNum + "-i" + itemNum));

        // Special condition if the title is a question name and it is to be aligned with answer then exit out of title generation.
        if ((itemObj.source === ddo.TITLE.SOURCE.QUE_NAME) && (tag.metadataExists(detailsObj.question, tag.DISPLAY_DEF_ALIGN_ANSWERS_WITH_QUESTION))) {
            return;
        }

        let htmlObj = null;
        let CustomTypographyStyle = null;
        let customLeftObj = null;
        let customCenterObj = null;
        let customRightObj = null;
        let customLeftObj2 = null;
        let customCenterObj2 = null;
        let customRightObj2 = null;
        let customObj = null;

        switch(itemType) {
            case ddo.OBJ.TYPE.SECTION_ITEM.TITLE:
                // itemType TITLE can be for category title bar, headers or footers or spacers
                let format = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.TITLE.FORMAT);
                let variant = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.TITLE.VARIANT);

                let convertedVariant = (typeof variant === "object") ? "" : font.getDefaultVariantForItem(ddo.OBJ.TYPE.SECTION_ITEM.TITLE, variant);  // Note: custom titles will be blank

                let fontObj= font.getFontObjFromVariant(convertedVariant); // MUI 5 converted to using fotsize in rem not pt, and our CSS not working this it to override

                // Check default text to see if custom styling requested
                if (itemObj.source === "D") {

                    if (tag.metadataExists(qnr, tag.CUSTOM_DDO_DEFAULTS)) {

                        variant = tag.metadataValue(qnr, tag.CUSTOM_DDO_DEFAULTS);
                        format = ddo.TITLE.FORMAT.CUSTOM;
                    }
                }

                let showGroupKey = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.TITLE.SHOW_GROUP_KEY);
                //let showGroupObjArray = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.TITLE.SHOW_GROUP_OBJ_ARRAY);

                let uploadedImageStyles = {
                    verticalAlign: 'middle',
                    height: '60px'
                };

                switch (format) {

                    case ddo.TITLE.FORMAT.SPACER:
                        htmlObj = <Grid style={sectionClass} key={detailsObj.itemObj.id} container item sm={12}></Grid>;
                        break;

                    case ddo.TITLE.FORMAT.CATEGORY_TITLE_BAR:  // =================================== CATEGORY TITLE BAR

                        htmlObj = getCategoryTitleBar(qnr, detailsObj.textObj, itemObj, convertedVariant, uploadedImageStyles, detailsObj.itemObj.id);
                        break;

                    case ddo.TITLE.FORMAT.PAGE_HEADER:
                    case ddo.TITLE.FORMAT.PAGE_FOOTER:
                    case ddo.TITLE.FORMAT.PAGE_FOOTER2:

                        if (format === ddo.TITLE.FORMAT.PAGE_HEADER) {
                            customLeftObj = custom.getCustomTitleTopography(qnr, tag.PAGE_HEADER_LEFT, "left");
                            customCenterObj = custom.getCustomTitleTopography(qnr, tag.PAGE_HEADER_CENTER, "center");
                            customRightObj = custom.getCustomTitleTopography(qnr, tag.PAGE_HEADER_RIGHT, "right");
                        }
                        else if (format === ddo.TITLE.FORMAT.PAGE_FOOTER2) {
                            customLeftObj = custom.getCustomTitleTopography(qnr, tag.PAGE_FOOTER_LEFT, "left");
                            customCenterObj = custom.getCustomTitleTopography(qnr, tag.PAGE_FOOTER_CENTER, "center");
                            customRightObj = custom.getCustomTitleTopography(qnr, tag.PAGE_FOOTER_RIGHT, "right");

                            customLeftObj2 = custom.getCustomTitleTopography(qnr, tag.PAGE_FOOTER_LEFT2, "left");
                            customCenterObj2 = custom.getCustomTitleTopography(qnr, tag.PAGE_FOOTER_CENTER2, "center");
                            customRightObj2 = custom.getCustomTitleTopography(qnr, tag.PAGE_FOOTER_RIGHT2, "right");
                        }
                        else if (format === ddo.TITLE.FORMAT.PAGE_FOOTER) {
                            customLeftObj = custom.getCustomTitleTopography(qnr, tag.PAGE_FOOTER_LEFT, "left");
                            customCenterObj = custom.getCustomTitleTopography(qnr, tag.PAGE_FOOTER_CENTER, "center");
                            customRightObj = custom.getCustomTitleTopography(qnr, tag.PAGE_FOOTER_RIGHT, "right");
                        }

                        htmlObj =
                            <Grid style={sectionClass} key={format + "-" + detailsObj.itemObj.id} container item sm={12}>
                                <div style={{width: '100%', display: 'flex'}}>
                                    <div style={{float: 'left', width: '30%'}}>
                                        {detailsObj.textObj.titleLeftIsImage ?
                                            <img src={`data:image/png;base64,${detailsObj.textObj.titleLeft}`} alt="" style={uploadedImageStyles}/>
                                            :
                                            customLeftObj.useCustom ?
                                                <customLeftObj.customTypography>{customLeftObj.title}</customLeftObj.customTypography>
                                                :
                                                <Typography variant={itemObj.variant}>{detailsObj.textObj.titleLeft}</Typography>
                                        }
                                    </div>
                                    <div style={{float: 'right', width: '40%', textAlign: 'center'}}>
                                        {detailsObj.textObj.titleCenterIsImage ?
                                            <img src={`data:image/png;base64,${detailsObj.textObj.titleCenter}`} alt="" style={uploadedImageStyles}/>
                                            :
                                            customCenterObj.useCustom ?
                                                <customCenterObj.customTypography>{customCenterObj.title}</customCenterObj.customTypography>
                                                :
                                                <Typography variant={itemObj.variant}>{detailsObj.textObj.titleCenter}</Typography>
                                        }
                                    </div>
                                    <div style={{float: 'right', width: '30%', textAlign: 'right', alignSelf: 'flex-end'}}>
                                        {detailsObj.textObj.titleRightIsImage ?
                                            <img src={`data:image/png;base64,${detailsObj.textObj.titleRight}`} alt="" style={uploadedImageStyles}/>
                                            :
                                            customRightObj.useCustom ?
                                                <customRightObj.customTypography>{customRightObj.title}</customRightObj.customTypography>
                                                :
                                                <Typography variant={itemObj.variant}>{detailsObj.textObj.titleRight}</Typography>
                                        }
                                    </div>
                                </div>

                                {(format === ddo.TITLE.FORMAT.PAGE_FOOTER2) ?
                                    <div style={{width: '100%', display: 'flex'}}>
                                        <div style={{float: 'left', width: '30%'}}>
                                            {detailsObj.textObj2.titleLeftIsImage ?
                                                <img src={`data:image/png;base64,${detailsObj.textObj2.titleLeft}`} alt="" style={uploadedImageStyles}/>
                                                :
                                                customLeftObj2.useCustom ?
                                                    <customLeftObj2.customTypography>{customLeftObj2.title}</customLeftObj2.customTypography>
                                                    :
                                                    <Typography variant={itemObj.variant}>{detailsObj.textObj2.titleLeft}</Typography>
                                            }
                                        </div>
                                        <div style={{float: 'right', width: '40%', textAlign: 'center'}}>
                                            {detailsObj.textObj2.titleCenterIsImage ?
                                                <img src={`data:image/png;base64,${detailsObj.textObj2.titleCenter}`} alt="" style={uploadedImageStyles}/>
                                                :
                                                customCenterObj2.useCustom ?
                                                    <customCenterObj2.customTypography>{customCenterObj2.title}</customCenterObj2.customTypography>
                                                    :
                                                    <Typography variant={itemObj.variant}>{detailsObj.textObj2.titleCenter}</Typography>
                                            }
                                        </div>
                                        <div style={{float: 'right', width: '30%', textAlign: 'right', alignSelf: 'flex-end'}}>
                                            {detailsObj.textObj2.titleRightIsImage ?
                                                <img src={`data:image/png;base64,${detailsObj.textObj2.titleRight}`} alt="" style={uploadedImageStyles}/>
                                                :
                                                customRightObj2.useCustom ?
                                                    <customRightObj2.customTypography>{customRightObj2.title}</customRightObj2.customTypography>
                                                    :
                                                    <Typography variant={itemObj.variant}>{detailsObj.textObj2.titleRight}</Typography>
                                            }
                                        </div>
                                    </div>
                                    : ""}

                            </Grid>;
                        break;
                    case ddo.TITLE.FORMAT.CUSTOM:

                        customObj = variant;  // Custom object was stored in variant

                        CustomTypographyStyle = custom.getCustomTypography(customObj);

                        let skipName = false;

                        if ((detailsObj.source === ddo.TITLE.SOURCE.QUE_NAME)||(detailsObj.source === ddo.TITLE.SOURCE.QUE_DESC)) {
                            // Check for custom question format
                            if (tag.metadataExists(detailsObj.question, tag.CUSTOM_DDO_QUE_FORMAT)) {
                                let questionCustomObj = JSON.parse(tag.metadataValue(detailsObj.question, tag.CUSTOM_DDO_QUE_FORMAT));

                                // If question is in likert mode display of name will be handled in likert section of singleSelect
                                skipName = (questionCustomObj.displayAs === 'likert');
                            }
                        }
                        if (detailsObj.source === ddo.TITLE.SOURCE.QUE_DESC) {
                            if (qnr.description !== "") {
                                detailsObj.text = qnr.description;
                            }
                        }
                        if (detailsObj.source === ddo.TITLE.SOURCE.QNR_SCRIPT_BEFORE) {
                            if (tag.metadataExists(qnr, tag.SCRIPT_BEFORE)) {
                                detailsObj.text = tag.metadataValue(qnr, tag.SCRIPT_BEFORE, "", "", true); // true = skip custom
                            }
                        }
                        if (detailsObj.source === ddo.TITLE.SOURCE.QNR_SCRIPT_AFTER) {
                            if (tag.metadataExists(qnr, tag.SCRIPT_AFTER)) {
                                detailsObj.text = tag.metadataValue(qnr, tag.SCRIPT_AFTER, "", "", true); // true = skip custom
                            }
                        }

                        let customScriptIconNone = false;
                        let customScriptIconSize = customObj.fontSize;
                        let customScriptIconColor = customObj.color;
                        let customScriptIconPadding = "2px";


                        if (detailsObj.isScript) {
                            customScriptIconNone = tag.metadataExists(detailsObj.currentTemplate, tag.DISPLAY_DEF_SCRIPT_ICON_NONE);
                            customScriptIconSize = tag.metadataValue(detailsObj.currentTemplate, tag.DISPLAY_DEF_SCRIPT_ICON_SIZE, "", customObj.fontSize);
                            customScriptIconColor = tag.metadataValue(detailsObj.currentTemplate, tag.DISPLAY_DEF_SCRIPT_ICON_COLOR, "", customObj.color);
                            customScriptIconPadding = tag.metadataValue(detailsObj.currentTemplate, tag.DISPLAY_DEF_SCRIPT_ICON_PADDING, "", customScriptIconPadding);
                        }


                        let customDescIconNone = false;
                        let customDescIconSize = customObj.fontSize;
                        let customDescIconColor = customObj.color;
                        let customDescIconPadding = "2px";


                        if (detailsObj.isDescription) {
                            customDescIconNone = tag.metadataExists(detailsObj.currentTemplate, tag.DISPLAY_DEF_DESCRIPTION_ICON_NONE);
                            customDescIconSize = tag.metadataValue(detailsObj.currentTemplate, tag.DISPLAY_DEF_DESCRIPTION_ICON_SIZE, "", customObj.fontSize);
                            customDescIconColor = tag.metadataValue(detailsObj.currentTemplate, tag.DISPLAY_DEF_DESCRIPTION_ICON_COLOR, "", customObj.color);
                            customDescIconPadding = tag.metadataValue(detailsObj.currentTemplate, tag.DISPLAY_DEF_DESCRIPTION_ICON_PADDING, "", customDescIconPadding);
                        }

                        if (!skipName) {
                            htmlObj = <Grid key={"TitleCustom-" + detailsObj.objKey} item sm={12} style={{...sectionClass, display: ddo.showGroup(qnr, showGroupKey) ? "inline" : "none"}}>
                                <div style={{textAlign: customObj.textAlign}}>
                                    <div style={{display: 'flex', alignItems: "center"}}>
                                        <div style={{display: (!detailsObj.isScript || customScriptIconNone) ? "none" : "inline"}}>
                                            <CommentIcon style={{fontSize: customScriptIconSize, color: customScriptIconColor, paddingTop: customScriptIconPadding}}/>
                                        </div>
                                        <div style={{display: (!detailsObj.isScript || customScriptIconNone) ? "none" : "inline"}}>
                                            &nbsp;
                                        </div>
                                        <div style={{display: (!detailsObj.isDescription || customDescIconNone) ? "none" : "inline"}}>
                                            <InfoIcon style={{fontSize: customDescIconSize, color: customDescIconColor, paddingTop: customDescIconPadding}}/>
                                        </div>
                                        <div style={{display: (!detailsObj.isDescription || customDescIconNone) ? "none" : "inline"}}>
                                            &nbsp;
                                        </div>
                                        <CustomTypographyStyle>{detailsObj.text}</CustomTypographyStyle>
                                    </div>
                                </div>
                            </Grid>;
                        }

                        break;
                    case ddo.TITLE.FORMAT.PLAIN:

                        // If text is "TOPOGRAPHY" Then display defaults chart
                        if ((detailsObj.text !== undefined) && (detailsObj.text.toUpperCase() === "TYPOGRAPHY")) {
                            htmlObj = font.displayDefaultTopographyDetails(pageNum, sectionNum, itemNum);
                        }
                        else {

                            htmlObj = <Grid key={"TitlePlain-" + (detailsObj.itemObj.id === null ? detailsObj.itemObj.source : "")} item sm={12} style={{...sectionClass, display: ddo.showGroup(qnr, showGroupKey) ? "inline" : "none"}}>
                                <div style={{textAlign: 'left'}}>
                                    <Typography fontSize={fontObj.fontSize} fontStyle={fontObj.fontStyle} color={fontObj.fontColor}>{detailsObj.text}</Typography>
                                </div>
                            </Grid>;
                        }
                        break;
                    case ddo.TITLE.FORMAT.PLAIN_CENTER:
                        // ToDo ZZZZZ if there is a description wait to do gray bar labels

                        htmlObj = <Grid key={"TitlePlainCenter-" + detailsObj.itemObj.id} item sm={12} style={{...sectionClass, display: ddo.showGroup(qnr, showGroupKey) ? "inline" : "none"}}>
                            <div style={{textAlign: 'center'}}>
                                <Typography fontSize={fontObj.fontSize} fontStyle={fontObj.fontStyle} color={fontObj.fontColor}>{detailsObj.text}</Typography>
                            </div>
                        </Grid>;
                        break;
                    case ddo.TITLE.FORMAT.PLAIN_RIGHT:
                        // ToDo ZZZZZ if there is a description wait to do gray bar labels

                        htmlObj = <Grid key={"TitlePlainRight-" + detailsObj.itemObj.id} item sm={12} style={{...sectionClass, display: ddo.showGroup(qnr, showGroupKey) ? "inline" : "none"}}>
                            <div style={{textAlign: 'right'}}>
                                <Typography fontSize={fontObj.fontSize} fontStyle={fontObj.fontStyle} color={fontObj.fontColor}>{detailsObj.text}</Typography>
                            </div>
                        </Grid>;
                        break;
                    case ddo.TITLE.FORMAT.COMMENT:
                        // ToDo ZZZZZ if there is a description wait to do gray bar labels
// debugger;
//                         htmlObj = <Grid key={"TitleComment-" + detailsObj.itemObj.id} item container sm={12} style={{display: ddo.showGroup(qnr, showGroupKey) ? "inline" : "none"}}>
//                                     <Grid item sm={1} style={{float: "left"}}>
//                                         <Comment/>
//                                     </Grid>
//                                     <Grid item sm={1} style={{float: "left"}}>
//                                         &nbsp;
//                                     </Grid>
//                                     <Grid item sm={10} style={{float: "left"}}>
//                                         <Typography variant={convertedVariant}>{detailsObj.text}</Typography>
//                                     </Grid>
//                                 </Grid>;

                        let scriptFontObj = font.getVariantPropertiesObj(convertedVariant);
                        let scriptIconNone = tag.metadataExists(detailsObj.currentTemplate, tag.DISPLAY_DEF_SCRIPT_ICON_NONE);
                        let scriptIconSize = tag.metadataValue(detailsObj.currentTemplate, tag.DISPLAY_DEF_SCRIPT_ICON_SIZE, "", scriptFontObj.iconSize);
                        let scriptIconColor = tag.metadataValue(detailsObj.currentTemplate, tag.DISPLAY_DEF_SCRIPT_ICON_COLOR, "", scriptFontObj.color);
                        let scriptIconPadding = tag.metadataValue(detailsObj.currentTemplate, tag.DISPLAY_DEF_SCRIPT_ICON_PADDING, "", scriptFontObj.iconPadding);

                        htmlObj = <Grid key={"TitleFormatComment-" + detailsObj.itemObj.id} item container sm={12} style={{...sectionClass, display: ddo.showGroup(qnr, showGroupKey) ? "inline" : "none"}}>
                            <div style={{display: 'flex', alignItems: "center"}}>
                                <div style={{display: scriptIconNone ? "none" : "inline"}}>
                                    <CommentIcon style={{fontSize: scriptIconSize, color: scriptIconColor, paddingTop: scriptIconPadding}}/>
                                </div>
                                <Typography fontSize={fontObj.fontSize} fontStyle={fontObj.fontStyle} color={fontObj.fontColor} style={{marginLeft: '5px'}}>{detailsObj.text}</Typography>
                            </div>
                            {/*{*/}
                            {/*    this.renderGrayBarLabelsIfRequired(itemObj, qnr, detailsObj.group, detailsObj.question)*/}
                            {/*}*/}
                        </Grid>;

                        break;
                    case ddo.TITLE.FORMAT.INFO:
                        // QQQQQ if there is a description wait to do gray bar labels

                        //let likertQuestion = false;
                        //let tempGroup = null;
                        //let tempQuestion = null;

                        if (itemObj.source === ddo.TITLE.SOURCE.QUE_NAME) {
                            detailsObj.questionId = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.ID);
                            //tempQuestion = ddo.getQuestion(qnr, detailsObj.questionId);

                            //likertQuestion = tag.metadataExists(tempQuestion, tag.DISPLAY_SINGLE_SELECT_AS_LIKERT);
                        }

                        //if (itemObj.source === ddo.TITLE.SOURCE.GRP_NAME) {

                        //let groupId = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.LIKERT_GROUP.ID);
                        //tempGroup = ddo.getGroup(qnr, groupId);
                        //}

                        let iconFontObj = font.getVariantPropertiesObj(convertedVariant);
                        let descIconNone = tag.metadataExists(detailsObj.currentTemplate, tag.DISPLAY_DEF_DESCRIPTION_ICON_NONE);
                        let descIconSize = tag.metadataValue(detailsObj.currentTemplate, tag.DISPLAY_DEF_DESCRIPTION_ICON_SIZE, "", iconFontObj.iconSize);
                        let descIconColor = tag.metadataValue(detailsObj.currentTemplate, tag.DISPLAY_DEF_DESCRIPTION_ICON_COLOR, "", iconFontObj.color);
                        let descIconPadding = tag.metadataValue(detailsObj.currentTemplate, tag.DISPLAY_DEF_DESCRIPTION_ICON_PADDING, "", iconFontObj.iconPadding);

                        htmlObj = <Grid key={"TitleFormatInfo-" + detailsObj.itemObj.id} item container sm={12} style={{...sectionClass, display: ddo.showGroup(qnr, showGroupKey) ? "inline" : "none"}}>
                            <div style={{display: 'flex', alignItems: "center"}}>
                                <div style={{display: descIconNone ? "none" : "inline"}}>
                                    <InfoIcon style={{fontSize: descIconSize, color: descIconColor, paddingTop: descIconPadding}}/>
                                </div>
                                <Typography fontSize={fontObj.fontSize} fontStyle={fontObj.fontStyle} color={fontObj.fontColor} style={{marginLeft: '5px'}}>{detailsObj.text}</Typography>
                            </div>
                            {/*{*/}
                            {/*    this.renderGrayBarLabelsIfRequired(itemObj, qnr, detailsObj.group, detailsObj.question)*/}
                            {/*}*/}
                        </Grid>;
                        break;
                    case ddo.TITLE.FORMAT.METADATA:

                        let tagName = detailsObj.text.substr(0, detailsObj.text.indexOf("|"));
                        let valueName = detailsObj.text.substr(detailsObj.text.indexOf("|") + 1);

                        fontObj = font.getFontObjFromVariant(font.VARIANT.CAPTION);

                        htmlObj = <Grid key={"TitleFormatMetadata-" + itemNum} item container sm={12} style={{...sectionClass, display: "inline"}}>
                            <div item sm={1} style={{float: "left"}}>
                                <Typography fontSize={fontObj.fontSize} fontStyle={fontObj.fontStyle} color={fontObj.fontColor}>{tagName}</Typography>
                            </div>
                            <div item sm={1} style={{float: "left"}}>
                                {":"}&nbsp;&nbsp;
                            </div>
                            <div item sm={10} style={{float: "left"}}>
                                <Typography fontSize={fontObj.fontSize} fontStyle={fontObj.fontStyle} color={fontObj.fontColor}><b>{valueName}</b></Typography>
                            </div>
                        </Grid>;
                        break;
                    default:
                        break;
                }
                break;
            case ddo.OBJ.TYPE.SECTION_ITEM.BUTTON:

                // let btnVariant = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.BUTTON.VARIANT);
                // //let btnConvertedVariant = font.convertVariantToPointsStr(btnVariant);  //"contained","outlined","text"]
                // let btnColor = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.BUTTON.COLOR);
                // btnColor = ddo.convertButtonColor(btnColor);
                let btnName = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.BUTTON.NAME);
                let btnMode = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.BUTTON.MODE);
                btnName = ddo.convertButtonNameDefault(btnName, btnMode);


                let onClickFunction = function () {};

                switch (btnMode) {
                    case ddo.BUTTON.MODE.START:
                        onClickFunction = this.props.start.bind(this);
                        break;
                    case ddo.BUTTON.MODE.CANCEL:
                        onClickFunction = this.props.cancel;
                        break;
                    case ddo.BUTTON.MODE.NEXT:
                        onClickFunction = this.props.next.bind(this);
                        break;
                    case ddo.BUTTON.MODE.PREVIOUS:
                        onClickFunction = this.props.previous.bind(this);
                        break;
                    case ddo.BUTTON.MODE.FINISH:
                        if (this.props.surveyEditMode === mode.READONLY_SURVEY) {
                            return "";  // No submit button in readonly
                        }
                        if (!this.props.portalPreview) {
                            onClickFunction = this.props.finish; // Dont allow submit onClick in PortalPreview
                        }
                        break;
                    case ddo.BUTTON.MODE.CLOSE:
                        onClickFunction = this.props.closeSurvey;
                        break;
                    case ddo.BUTTON.MODE.VIEW_PDF:
                        onClickFunction = this.props.next;
                        break;
                    default:
                        break;
                }

                let btnKeyQualifier =  detailsObj.itemObj.mode + "-" + pageNum + "-" + sectionNum + "-" + itemNum;

                // let ariaLabel = this.getAriaLabelForButton(btnMode, btnName); NOTE: This does not appear to work for buttons

                if (tag.metadataExists(qnr, tag.SYSTEM_BUTTON_CUSTOM, "", "", true)) {

                    let styleObj = {
                        color: tag.metadataValue(qnr, tag.SYSTEM_BUTTON_CUSTOM_TEXT_COLOR, "", tag.DEFAULT_SELECTED_COLOR, true)  + " !important",
                        height: tag.metadataValue(qnr, tag.SYSTEM_BUTTON_CUSTOM_HEIGHT, "", 20, true),
                        width: tag.metadataValue(qnr, tag.SYSTEM_BUTTON_CUSTOM_WIDTH, "", 100, true),
                        background: tag.metadataValue(qnr, tag.SYSTEM_BUTTON_CUSTOM_BACKGROUND_COLOR, "", "white", true)  + " !important",
                        borderColor: tag.metadataValue(qnr, tag.SYSTEM_BUTTON_CUSTOM_BORDER_COLOR, "", "white", true)  + " !important",
                        border: tag.metadataValue(qnr, tag.SYSTEM_BUTTON_CUSTOM_BORDER, "", "none", true),
                        borderWidth: tag.metadataValue(qnr, tag.SYSTEM_BUTTON_CUSTOM_BORDER_WIDTH, "", 0, true),
                        borderRadius: tag.metadataValue(qnr, tag.SYSTEM_BUTTON_CUSTOM_BORDER_RADIUS, "", 0, true),
                        hoverColor: tag.metadataValue(qnr, tag.SYSTEM_BUTTON_CUSTOM_HOVER_TEXT_COLOR, "", tag.DEFAULT_SELECTED_COLOR, true)  + " !important",
                        hoverFontWeight: tag.metadataValue(qnr, tag.SYSTEM_BUTTON_CUSTOM_HOVER_FONT_WEIGHT, "", "normal", true)  + " !important",
                        hoverBackground: tag.metadataValue(qnr, tag.SYSTEM_BUTTON_CUSTOM_HOVER_BACKGROUND_COLOR, "", "white", true)  + " !important",
                        hoverBorderColor: tag.metadataValue(qnr, tag.SYSTEM_BUTTON_CUSTOM_HOVER_BORDER_COLOR, "", "white", true)  + " !important",
                        hoverBorderWidth: tag.metadataValue(qnr, tag.SYSTEM_BUTTON_CUSTOM_HOVER_BORDER_WIDTH, "", 0, true),
                    };

                    const SystemButtonStyle = styled(Button)({
                        color: styleObj.color,
                        height: styleObj.height,
                        width: styleObj.width,
                        background: styleObj.background,
                        borderColor: styleObj.borderColor,
                        border: styleObj.border,
                        borderRadius: styleObj.borderRadius,
                        borderWidth: styleObj.borderWidth,
                        '&:hover': {
                            color: styleObj.hoverColor,
                            fontWeight: styleObj.hoverFontWeight,
                            background: styleObj.hoverBackground,
                            borderColor: styleObj.hoverBorderColor,
                            borderWidth: styleObj.hoverBorderWidth,
                        }
                    });

                    const SystemButtonTheme = createTheme({
                        typography: {
                            fontFamily: tag.metadataValue(qnr, tag.SYSTEM_BUTTON_CUSTOM_TEXT_FONT, "", font.DEFAULT_FONT_FAMILY, true),
                            fontSize: tag.metadataValue(qnr, tag.SYSTEM_BUTTON_SIZE, "", 14, true), // px
                        }
                    });

                    htmlObj =  <Grid style={sectionClass} key={"ButtonCustom-" + btnKeyQualifier} item>
                        <ThemeProvider theme={SystemButtonTheme}>
                            <SystemButtonStyle onClick={onClickFunction}>
                                {btnName}
                            </SystemButtonStyle>
                        </ThemeProvider>
                    </Grid>;

                } else {

                    htmlObj =  <Grid key={"Button-" + btnKeyQualifier} item>
                        <Button
                            variant={tag.metadataValue(qnr, tag.SYSTEM_BUTTON_VARIANT, "", "text", true)}
                            sx={{color: "#2497F3"}}
                            size={tag.metadataValue(qnr, tag.SYSTEM_BUTTON_VARIANT, "", "large", true)}
                            onClick={onClickFunction}
                        >
                            {btnName}
                        </Button>
                    </Grid>;
                }

                break;
            case ddo.OBJ.TYPE.SECTION_ITEM.QUESTION:

                detailsObj.questionId = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.ID);
                detailsObj.question = ddo.getQuestion(qnr, detailsObj.questionId);
                let questionShowGroupKey = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.SHOW_GROUP_KEY);
                //let questionShowGroupObjArray = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.SHOW_GROUP_OBJ_ARRAY);
                detailsObj.qNum = ddo.getObjValue(itemObj, ddo.OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.SHOW_NUMBER);

                htmlObj =  <Grid key={"QuestionGrid-" + detailsObj.itemObj.id + "-" + itemNum} item style={{...sectionClass, width: "100%", minHeight: "fit-content", display: ddo.showGroup(qnr, questionShowGroupKey) ? "inline" : "none"}} xs={12}>
                    <PortalQuestion key={"Question-" + detailsObj.question.id + "-" + itemNum}
                                    questionnaire={qnr}
                                    showGroupKey={questionShowGroupKey}
                                    question={detailsObj.question}
                                    questionNumber={detailsObj.qNum}
                                    itemObj={itemObj}
                                    answerQuestionOptions={this.props.answerQuestionOptions}
                                    answerQuestionTemplateOptions={this.props.answerQuestionTemplateOptions}
                                    answerQuestionText={this.props.answerQuestionText}
                                    answerQuestionTemplateText={this.props.answerQuestionTemplateText}
                                    displayMode={this.props.displayMode}
                                    surveyEditMode={this.props.surveyEditMode}
                                    theme={this.props.theme}
                        //likertIndex={index}
                        //surveyEditMode={this.props.surveyEditMode}
                    />
                </Grid>;
                break;

            case ddo.OBJ.TYPE.SECTION_ITEM.LIKERT_GROUP:

                let likertGroup = ddo.getGroup(qnr, itemObj[ddo.OBJ.KEY.PAGE.SECTION.ITEM.LIKERT_GROUP.ID]);
                let likertQuestions = ddo.getLikertGroupQuestions(itemObj);
                //let likertShowGroupKey = itemObj.questions[0].showGroupKey; ToDo ZZZZZ this needs to return by question and ignore if metadata says to since LIKERTS only have one answers set and multiple questions
                //let likertShowGroupObjArray = itemObj.questions[0].showGroupObjArray;

                htmlObj =  <Grid key={"Likert-" + detailsObj.itemObj.id} item style={{...sectionClass, width: "100%", minHeight: "fit-content", display: 'inline'}} xs={12}>
                    {likertQuestions.map((questionItemObj, index) =>
                        <PortalQuestion     key={"LikertQuestion-" + index + "-" + questionItemObj.id}
                                            questionnaire={qnr}
                                            showGroupKey={ddo.getLikertQuestionShowGroupKey(qnr, questionItemObj)}
                                            group={likertGroup}
                                            question={ddo.getQuestion(qnr, questionItemObj[ddo.OBJ.KEY.PAGE.SECTION.ITEM.QUESTION.ID])}
                                            questionNumber={questionItemObj.showNumber}
                                            itemObj={itemObj}
                                            answerQuestionOptions={this.props.answerQuestionOptions}
                                            answerQuestionTemplateOptions={this.props.answerQuestionTemplateOptions}
                                            answerQuestionText={this.props.answerQuestionText}
                                            answerQuestionTemplateText={this.props.answerQuestionTemplateText}
                                            theme={this.props.theme}
                                            likertIndex={index}
                                            displayMode={this.props.displayMode}
                                            surveyEditMode={this.props.surveyEditMode}
                        />
                    )}
                </Grid>;
                break;
            case ddo.OBJ.TYPE.SECTION_ITEM.ICON:

                htmlObj = <div key="GreenCheckIcon" style={{display: 'flex', justifyContent: 'center'}}><img src={green_checkmark} alt="Green check mark logo for questionnaire complete"/></div>;
                break;
            default:

                break;
        }

        return htmlObj;
    };


    // -----------------------------------------------------------------------------------------------------------------
    // RENDER
    // -----------------------------------------------------------------------------------------------------------------

    render() {

        let pageSections = this.props.pageDefObj[ddo.OBJ.KEY.DDO.PAGE_SECTION_ARRAY];

        let qnr = this.getQnrObj(); // Get Questionnaire Obj (Instance or Template)

        return (
            ((this.props.questionnaireInstance) || (this.props.questionnaireTemplate && qnr?.questionCategoryTemplates)) &&
            <form key={"MainForm-id-" + qnr.id + "-portalCount-" + this.props.portalCount}>
                {pageSections.map((section, index) =>
                    this.addSectionToPage(section, this.props.pageDefObjCurrIndex, index, this.props.totalPages)
                )}
            </form>
        );
    }
}

PortalPageRenderer.defaultProps = {
    showProgress: true,
    questionnaireInstance: null,
    questionnaireTemplate: null
};


PortalPageRenderer.propTypes = {
    //classes: PropTypes.object.isRequired,
    pageDefObj: PropTypes.object.isRequired,
    pageDefObjCurrIndex: PropTypes.number,
    totalPages: PropTypes.number,
    questionnaireInstance: PropTypes.object,
    questionnaireTemplate: PropTypes.object,
    questionnaire: PropTypes.object,
    start: PropTypes.func,
    cancel: PropTypes.func,
    finish: PropTypes.func,
    next: PropTypes.func,
    previous: PropTypes.func,
    history: PropTypes.object,
    portal: PropTypes.object,
    displayMode: PropTypes.string,
    surveyEditMode: PropTypes.string,
    templateType: PropTypes.string,
    portalPreview: PropTypes.bool,
    portalCount: PropTypes.number
};

function mapStateToProps(state) {
    return {
        theme: state.theme,
        questionnaireTemplateFromStore: state.portal.questionnaireTemplate
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        dispatch,
        answerQuestionOptions,
        answerQuestionTemplateOptions,
        answerQuestionText,
        answerQuestionTemplateText,
        setTheme
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PortalPageRenderer);
