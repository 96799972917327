
//AUTHORIZATION ACTIONS
export const TOKEN_SET = "TOKEN_SET";
export const TOKEN_GET = "TOKEN_GET";
export const TOKEN_CLEAR = "TOKEN_CLEAR";
export const TOKEN_REJECT_KEY = "TOKEN_REJECT_KEY";
export const TOKEN_INITIALIZE = "TOKEN_INITIALIZE";

//DRAWER ACTIONS
export const OPEN_DRAWER = "OPEN_DRAWER";
export const CLOSE_DRAWER = "CLOSE_DRAWER";

//NOTIFICATION ACTIONS
export const NOTIFICATION_OPEN = "NOTIFICATION_OPEN";
export const NOTIFICATION_CLOSED = "NOTIFICATION_CLOSED";


export const QUESTIONNAIRE_START = "QUESTIONNAIRE_START";
export const QUESTIONNAIRE_COMPLETE = "QUESTIONNAIRE_COMPLETE";
export const QUESTIONNAIRE_GET_FULL_SUCCESS = "QUESTIONNAIRE_GET_FULL_SUCCESS";

export const QUESTION_ANSWER_SUCCESS = "QUESTION_ANSWER_SUCCESS";

export let QUESTION_TEMPLATE_ANSWER_SUCCESS = "QUESTION_TEMPLATE_ANSWER_SUCCESS";

export let INCREMENT_PORTAL_COUNT = "INCREMENT_PORTAL_COUNT";

export const SET_THEME = "SET_THEME";

// PORTAL ==============================================================================================================

export const GET_QUESTIONNAIRE_INSTANCE_ID_FROM_REF_KEY_SUCCESS = "GET_QUESTIONNAIRE_INSTANCE_ID_FROM_REF_KEY_SUCCESS";
export const GET_QUESTIONNAIRE_READONLY_INSTANCE_ID_FROM_REF_KEY_SUCCESS = "GET_QUESTIONNAIRE_READONLY_INSTANCE_ID_FROM_REF_KEY_SUCCESS";
export const GET_QUESTIONNAIRE_INSTANCE_SUCCESS = "GET_QUESTIONNAIRE_INSTANCE_SUCCESS";
export const SET_QUESTIONNAIRE_INSTANCE_NULL = "SET_QUESTIONNAIRE_INSTANCE_NULL";

export const SET_QUESTIONNAIRE_TEMPLATE_SUCCESS = "SET_QUESTIONNAIRE_TEMPLATE_SUCCESS";
export const SET_TEMPLATE_OBJ = "SET_TEMPLATE_OBJ";
export const SET_QUESTIONNAIRE_TEMPLATE_NULL = "SET_QUESTIONNAIRE_TEMPLATE_NULL";

export const PORTAL_CURRENT_PAGE = "PORTAL_CURRENT_PAGE";
export const PORTAL_NEXT_PAGE = "PORTAL_NEXT_PAGE";
export const PORTAL_PREV_PAGE = "PORTAL_PREV_PAGE";

