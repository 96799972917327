import { notificationOpen } from "../actions/notificationActions";
import { clearToken } from "../actions/responseActions";
import * as system from "../constants/systemConstants";
import * as tag from "../constants/customMetadataConstants";


export const errorResponse = async function(error) {
    let message = error.message;
    let loginMessage = "";

    // ref key invalid
    if (error.status === 404) {
        loginMessage = system.ALERT_MESSAGE_QUESTIONNAIRE_INVALID_KEY;
    }
    // ref deactivated (admin action), can set using deactivatedDate: "2020-08-01T20:59:07.748Z"
    else if (error.status === 403) {
        loginMessage = system.ALERT_MESSAGE_QUESTIONNAIRE_DEACTIVATED;
    }
    // questionnaire completed
    else if (error.status === 409) {
        loginMessage = system.ALERT_MESSAGE_QUESTIONNAIRE_COMPLETED;
    }
    // questionnaire expired, can set using expirationDate: "2020-08-01T20:59:07.748Z"
    else if (error.status === 410) {
        loginMessage = system.ALERT_MESSAGE_QUESTIONNAIRE_EXPIRED;
    }
    else if (error.status === 423) {
        loginMessage = system.ALERT_MESSAGE_QUESTIONNAIRE_UNAVAILABLE;
    }
    else {
        system.systemConstants.storeDispatch(notificationOpen(message, error));
    }
    system.systemConstants.storeDispatch(clearToken(loginMessage));
};
