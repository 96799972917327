import {styled} from "@mui/material/styles";
import Typography from '@mui/material/Typography';
import * as tag from "../constants/customMetadataConstants";
import * as font from "../utilities/fontUtilities";

export function getCustomTitleTopography(iObj, metadataTag, alignPosition) {

    let tempCustomTypography = null;
    let useCustom = false;
    let customTextObj = "";
    let title = "";

    if (tag.metadataExists(iObj, metadataTag)) {
        customTextObj = tag.metadataValue(iObj, metadataTag);

        // If its a string its metadata, othewise UI based
        if (typeof customTextObj !== "string") {
            useCustom = true;
            tempCustomTypography = getCustomTypography(customTextObj, "", alignPosition);
            title = customTextObj.title;
        }
        else {
            title = customTextObj;
        }
    }

    return {"customTypography": tempCustomTypography, "useCustom": useCustom, "title": title, customTextObj: customTextObj};
}


export function getCustomTypography(customTextObj, alignPosition = "left", fontSize = "", pyramidRightOnly = false) {

    let tempfontSize = "12pt"
    if ((fontSize !== "caption") && fontSize.includes("pt")) {
        tempfontSize = fontSize;
    }
    else {
        tempfontSize = font.convertVariantToPointsStr(fontSize);
    }

    let tempCustomTypography = null;

    let tempObj = null;

    if (customTextObj !== null) {

        tempObj = customTextObj.customObj !== undefined ? customTextObj.customObj : customTextObj;

        if (tempObj) {

            tempCustomTypography = styled(Typography)({
                //float: alignPosition,
                //paddingLeft: tempObj.indent !== "" ? tempObj.indent : "0px",
                fontFamily: tempObj.fontFamily !== "" ? tempObj.fontFamily : font.DEFAULT_FONT_FAMILY,
                fontWeight: tempObj.fontWeight !== "" ? tempObj.fontWeight : "normal",
                color: tempObj.colorType === "select" ? tempObj.color : tempObj.colorEntry,
                fontSize: tempObj.fontSize !== "" ? tempObj.fontSize : tempfontSize,  // 2em, 18pt
                fontStyle: tempObj.fontStyle !== "" ? tempObj.fontStyle : "normal",
                textAlign: pyramidRightOnly ? "center" : alignPosition,  // left center right
                opacity: tempObj.opacity !== "" ? tempObj.opacity : "100%",
                // marginBottom: likertIndex !== 0 && rightAndAlignedWithQuestions ? '20px' : '0px'
            });
        }
    }
    else {

        tempCustomTypography = styled(Typography)({
            //float: alignPosition,
            //paddingLeft: tempObj.indent !== "" ? tempObj.indent : "0px",
            fontFamily: font.DEFAULT_FONT_FAMILY,
            fontWeight: "normal",
            color: "black",
            fontSize: tempfontSize,  // 2em, 18pt
            fontStyle: "normal",
            textAlign: pyramidRightOnly ? "center" : alignPosition,  // left center right
            opacity: "100%",
            // marginBottom: likertIndex !== 0 && rightAndAlignedWithQuestions ? '20px' : '0px'
        });

    }

    return tempCustomTypography;
}



// TODO Convert RGB to HEX Color

// function componentToHex(c) {
//     var hex = c.toString(16);
//     return hex.length == 1 ? "0" + hex : hex;
// }
//
// function rgbToHex(r, g, b) {
//     return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
// }
//
// function hexToRgb(hex) {
//     var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
//     return result ? {
//         r: parseInt(result[1], 16),
//         g: parseInt(result[2], 16),
//         b: parseInt(result[3], 16)
//     } : null;
// }
//
// function rgbToHex(r, g, b) {
//     return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
// }

// To do this without the background opacity impacting the font opacity you must use rgba(r, g, b, o)
// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
