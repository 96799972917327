import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import dayjs from 'dayjs';
import TextField from "@mui/material/TextField";
import Stack from '@mui/material/Stack';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ArgoAppBar from "../common/ArgoAppBar";
import ArgoToolbarHook from "../common/ArgoToolbarHook";
import ArgoTextFieldHook from "../common/ArgoTextFieldHook";
import {toLocale} from '../../utilities/dateFormats';
import * as validate from "../../utilities/validate";
import * as TEMPLATE from "../../constants/templateConstants";


const START_DATE_ERROR_TEXT = "Start date is required";
const NOTE_ERROR_TEXT = "Note is required";


class QuestionnairePublishOrChangeDateDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDateErrorText: this.props.buttonLabel === "PUBLISH" ? START_DATE_ERROR_TEXT : " ",
            expirationDaysErrorText: " ",
            expirationDescriptionErrorText: " ",
            noteErrorText: NOTE_ERROR_TEXT,
            toolbarErrorText: "",
            startDate: validate.notNull(this.props.startDate) ? new Date(toLocale(this.props.startDate)) : null,
            endDate: validate.notNull(this.props.endDate) ? new Date(toLocale(this.props.endDate)) : null,
            note : "",
            expirationDays: this.props.expirationDays,
            expirationDescription: this.props.expirationDescription,
            dateError: validate.notNull(this.props.startDate),
            dateDTO: {
                startDate: this.props.startDate,
                endDate: this.props.endDate,
                expirationDays: this.props.expirationDays,
                expirationDescription: this.props.expirationDescription,
                displayShortForm: false,
            },
        };
    }

    componentDidMount() {
        this.checkForErrorsOnPage()
    }

    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {

        switch (buttonTitle) {
            case "PUBLISH":
            case "SUBMIT":
                // Note: Change date and Publish from questionnaire list should need loading state
                this.props.setLoadingState();  // Set questionnaire loading state to true for render animation
                this.props.action(this.props.template.id, this.state.dateDTO, this.props.template.templateKey, this.props.filter, this.props.versionsFilter);
                break;
            case "CANCEL":
                this.props.closeDrawer();
                break;
            // no default
        }
    }

    onDateFieldChange(fieldName, value) {

        let newDateDTO = {...this.state.dateDTO};

        let startDateErrorText = " ";

        let startDate = fieldName === "startDate" ? dayjs(value) : (this.state.startDate === null ? null: dayjs(this.state.startDate));
        let endDate = fieldName === "endDate" ? dayjs(value) : (this.state.endDate === null ? null: dayjs(this.state.endDate));

        if (fieldName === "startDate") {
            if ((startDate && endDate) && (startDate > endDate)) {
                newDateDTO["endDate"] = null; // If end date exists and is less then start date clear end date field
            }
        }
        else {
            newDateDTO["endDate"] = endDate;
        }

        newDateDTO[fieldName] = dayjs(value).format("YYYY-MM-DD");
        if (startDate === null) {
            startDateErrorText = START_DATE_ERROR_TEXT;
        }

        // -------------------------------------------------------------------------------------------------------------

        this.setState({startDate: newDateDTO["startDate"], endDate: newDateDTO["endDate"] === undefined ? null : newDateDTO["endDate"], startDateErrorText: startDateErrorText, dateDTO: newDateDTO}, () => this.checkForErrorsOnPage());
    }

    onTextChange = (event, fieldName) => {

        let value = event.target.value.trim();

        let errorText = " ";
        let errorTextFieldName = fieldName + "ErrorText";

        switch (fieldName) {

            case "note":
                errorText = (value === "") ? NOTE_ERROR_TEXT : " ";
                break;
            case "expirationDays":
                errorText = (value <= 0) ? "Expiration days Cannot be zero or negative" : " ";
                break;
            case "expirationDescription":
                errorText = (value === "") ? "Expiration description is required" : " ";
                break;
            // no default
        }

        this.setState({[fieldName]: value, [errorTextFieldName]: errorText}, () => this.checkForErrorsOnPage());
    };

    checkForErrorsOnPage() {

        let toolbarErrorTextArray = [];
        let toolbarErrorText = "";
        if (this.state.startDateErrorText.trim() !== "") {
            toolbarErrorTextArray.push(this.state.startDateErrorText);
        }
        if (this.state.expirationDaysErrorText.trim() !== "") {
            toolbarErrorTextArray.push(this.state.expirationDaysErrorText);
        }
        if (this.state.expirationDescriptionErrorText.trim() !== "") {
            toolbarErrorTextArray.push(this.state.expirationDescriptionErrorText);
        }
        if (this.state.noteErrorText.trim() !== "") {
            toolbarErrorTextArray.push(this.state.noteErrorText);
        }
        toolbarErrorText = toolbarErrorTextArray.join(",  "); // Create a comma delimited array of all error text

        this.setState({toolbarErrorText: toolbarErrorText});
    }

    render() {

        return (
            <div>

                <ArgoAppBar
                    title={"Change Date:"}
                    titleFor={this.props.title}
                    showMenuIconButton={false}
                    noIcon={true}
                    isDrawer={true}
                    width={questionnairePublishOrChangeDateDrawerProps.width}
                />

                <div style={{height: '64px'}}></div>

                <ArgoToolbarHook
                    fileName="QuestionnairePublishOrChangeDateDrawer"
                    fieldName="publish-submit-or-cancel"
                    buttonTitles={[this.props.buttonLabel, "CANCEL"]}
                    toolbarErrorText={this.state.toolbarErrorText}
                    onClick={this.handleToolbarButtonClick}
                    width={questionnairePublishOrChangeDateDrawerProps.width}
                />

                <div style={{marginLeft: "20px", marginRight: "20px", marginTop: "30px"}}>

                    <div style={{height: "40px"}}>&nbsp;</div>

                    <Stack spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker id="QuestionnairePublishOrChangeDateDrawer-start-date"
                                               fullWidth
                                               value={this.state.startDate}
                                               onChange={e => this.onDateFieldChange("startDate", e, e["$d"])}
                                               label="Start date *"
                                               container="inline"
                                               autoFocus
                                               renderInput={(params) => <TextField  {...params} variant="standard"/>}
                                               error={this.state.dateError}
                                               helperText={this.state.startDateErrorText}
                            />

                            <DesktopDatePicker id="QuestionnairePublishOrChangeDateDrawer-end-date"
                                               fullWidth
                                               onChange={e => this.onDateFieldChange("endDate", e, e["$d"])}
                                               value={this.state.endDate}
                                               label="End date"
                                               disabled={this.state.startDate === null}
                                               minDate={this.state.startDate}
                                               renderInput={(params) => <TextField  {...params} variant="standard"/>}
                            />
                        </LocalizationProvider>

                        {/*Note: expiration days and expiration description were decided to only be available on the change date screen.  I believe it should also be available on the PUBLISH screens.*/}

                        {this.props.buttonLabel !== "PUBLISH" ?
                            <ArgoTextFieldHook
                                fileName="QuestionnairePublishOrChangeDateDrawer"
                                fieldName="expirationDays"
                                label="Expiration in days *"
                                value={this.state.expirationDays}
                                type="number"
                                onChange={this.onTextChange}
                                errorText={this.state.expirationDaysErrorText}
                            /> : ""
                        }

                        {this.props.buttonLabel !== "PUBLISH" ?
                            <ArgoTextFieldHook
                                fileName="QuestionnairePublishOrChangeDateDrawer"
                                fieldName="expirationDescription"
                                label="Expiration description"
                                value={this.state.expirationDescription}
                                multiline={true}
                                onChange={this.onTextChange}
                                errorText={this.state.expirationDescriptionErrorText}
                            /> : ""
                        }

                        <ArgoTextFieldHook
                            fileName="QuestionnairePublishOrChangeDateDrawer"
                            fieldName="note"
                            label="Note *"
                            value={this.state.note}
                            multiline={true}
                            onChange={this.onTextChange}
                            errorText={this.state.noteErrorText}
                        />

                    </Stack>

                    <div style={{fontSize: "12px"}}>* Indicates required field</div>

                    <div style={{height: '20px'}}></div>

                    {this.props.currentStartDate !== "" ?
                        <div>
                            <div style={{float: 'left', paddingRight: '10px', color: 'gray'}}>Previous Start Date:</div>
                            <div style={{float: 'left'}}>{this.props.currentStartDate}</div>
                        </div> : null
                    }

                </div>

            </div>
        );
    }
}

QuestionnairePublishOrChangeDateDrawer.defaultProps = {
    setLoadingState: function () {}
};

QuestionnairePublishOrChangeDateDrawer.propTypes = {
    closeDrawer: PropTypes.func,
    template: PropTypes.object,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    title: PropTypes.string,
    buttonLabel: PropTypes.string,
    action: PropTypes.func, //The api for this is (ID, changeDTO, templateKey)
    filter: PropTypes.object,
    versionsFilter: PropTypes.object,
    currentStartDate: PropTypes.string,
    setLoadingState: PropTypes.func,
};

const questionnairePublishOrChangeDateDrawerProps = {
    opensecondary: true,
    width: "55%",
    docked: false,
    anchor: "right"
};

function mapStateToProps(state) {
    return {
        filter: state.filter[TEMPLATE.FILTER.current.questionnaire],
        versionsFilter: state.filter[TEMPLATE.FILTER.versions.questionnaire]
    };
}

export default connect(mapStateToProps)(QuestionnairePublishOrChangeDateDrawer);
export {questionnairePublishOrChangeDateDrawerProps};


