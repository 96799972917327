import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {closeDrawer} from "../../actions/drawerActions";
import {getQuestionnaireGraph, clearQuestionnaireGraph} from "../../actions/questionnaireTemplateActions";
import ArgoAppBar from "../common/ArgoAppBar";
import * as TEMPLATE from "../../constants/templateConstants";
import ArgoPortalPreview from "../common/ArgoPortalPreview";


class QuestionnairePortalPreviewDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.props.getQuestionnaireGraph(this.props.templateId);
    }

    componentWillUnmount() {
        this.props.clearQuestionnaireGraph();
    }

    render() {

        let templateGraphClone = JSON.parse(JSON.stringify(this.props.questionnaireTemplateGraph));

        // Wait for questionnaire template graph to be returned from the server
        if (templateGraphClone?.id) {
            return (
                <ClickAwayListener onClickAway={(event) => {
                    // Checks is target is BODY first because portal preview DDLBs cause target to be body and we shouldn't close on that
                    if (event.target.tagName !== 'BODY') this.props.closeDrawer()
                }}>
                    <div id="QuestionnairePortalPreviewDrawer-container-div" style={{height: "100%"}}>
                        <ArgoAppBar
                            title={"Portal Preview:"}
                            titleFor={templateGraphClone.name}
                            showMenuArrowButton="LEFT"
                            onLeftIconButtonTouchTap={this.props.closeDrawer}
                            isDrawer={true}
                            width={questionnairePortalPreviewDrawerProps.width}
                        />

                        <div style={{height: '64px'}}></div>

                        <div id="QuestionnairePortalPreviewDrawer-div">
                            <ArgoPortalPreview
                                calledBy="QuestionnairePortalPreviewDrawer"
                                templateType={TEMPLATE.TYPE.QUESTIONNAIRE_GRAPH}
                                template={templateGraphClone}
                            />
                        </div>
                    </div>
                </ClickAwayListener>
            )
        }
    }
}

QuestionnairePortalPreviewDrawer.defaultProps = {

};

QuestionnairePortalPreviewDrawer.propTypes = {
    templateId: PropTypes.number
};

function mapStateToProps(state) {
    return {
        questionnaireTemplateGraph: state.questionnaireTemplateGraph,
        portalCount: state.portal.portalCount
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getQuestionnaireGraph, clearQuestionnaireGraph, closeDrawer}, dispatch);
}

const questionnairePortalPreviewDrawerProps = {
    opensecondary: true,
    width: "55%",
    docked: false,
    anchor: "right"
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnairePortalPreviewDrawer);
export {questionnairePortalPreviewDrawerProps};
