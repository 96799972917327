
export const SINGLE_SELECT_RADIO  = "type-radio";
export const SINGLE_SELECT_OUTLINED_RADIO  = "type-outlined-radio";
export const SINGLE_SELECT_STANDARD_BUTTON  = "type-standard-button";
export const SINGLE_SELECT_CLEAR_BUTTON  = "type-clear-button";
export const SINGLE_SELECT_TEXT_BUTTON  = "type-text-button";
export const SINGLE_SELECT_DDLB  = "type-ddlb";
export const SINGLE_SELECT_DDLB_STATES = "type-ddlb-states";
export const SINGLE_SELECT_ICONS = "type-icons";
export const SINGLE_SELECT_LIKERT = "type-likert";







