import {toLocale} from "./dateFormats";


export default function formatCopyText(row, event, templateType) {

    let text = "";
    let copyFlag = false;

    if ((event.altKey)&&(event.ctrlKey)) {
        copyFlag = true;
        text = JSON.stringify(row);
    }
    else if (event.altKey) {
        copyFlag = true;
        text = templateType + "\n" +
            "Name: " + row.name + "\n" +
            "Qualifier: " + (row.nameQualifier === null ? "" : row.nameQualifier) + "\n" +
            "Description: " + (row.description !== undefined ? row.description : "") + "\n" +
            "Key: " + (row.templateKey !== undefined ? row.templateKey : "") + "\n" +
            "ID: " + row.id.toString() + "\n" +
            "Date Created: " + toLocale(row.createdDate)  + "\n" +
            "Date Last Modified: " + toLocale(row.lastModifiedDate)  + "\n" +
            "Reference Key: " + (row.templateKeyRef !== null ? row.templateKeyRef : "");
    }
    else if (event.ctrlKey) {
        copyFlag = true;
        text = (row.templateKey !== undefined ? row.templateKey : row.id);
    }

    if (copyFlag) {
        if (navigator.clipboard) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    //debugger; Copied
                })
                .catch(() => {
                    //debugger; Not copied
                });
        }
    }

    return null;
}
