import {blue, common, grey, lightBlue} from '@mui/material/colors';
import 'roboto-fontface';

// Note - File not currently used?

// const palette = {
//     type: "light",
//     primary: {
//         light: lightBlue[700],
//         main: lightBlue[900],
//         dark: grey[400],
//         contrastText: common["white"]
//     },
//     secondary: {
//         light: blue[300],
//         main: blue[500],
//         dark: blue[700],
//         contrastText: common["white"]
//     }
// };

export default {
    // palette: palette,
    palette: {
        type: "dark",
        primary: {
            light: lightBlue[700],
            main: lightBlue[900],
            dark: grey[400],
            contrastText: common["white"]
        },
        secondary: {
            light: blue[300],
            main: blue[500],
            dark: blue[700],
            contrastText: common["white"]
        }
    },
    overrides: {
        MuiCard: {
            root: {
                minWidth: "450px",
            }
        },
        MuiFormControl: {
            root: {
                margin: 0,
                border: 0,
                display: "inline-flex",
                padding: 0,
                position: "relative",
                minWidth: 0,
                flexDirection: "column",
                verticalAlign: "top",
                width: "100%",
            }
        }
    },
    typography: {
        useNextVariants: true,
    }
};
