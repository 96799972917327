import React, {Component} from "react";
import PropTypes from "prop-types";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ArgoTextFieldHook from "./ArgoTextFieldHook";


class ArgoButtonDisplayCustom extends Component {

    constructor(props) {
        super(props);

        //let value = props.initToObj;

        if (props.initToObj === null) {
            this.state = {};
        }
        else {
            this.state = {
                variant: props.initToObj.variant,
                background: props.initToObj.background,
                backgroundImage: props.initToObj.backgroundImage,
                borderColor: props.initToObj.borderColor,
                borderRadius: props.initToObj.borderRadius,
                border: props.initToObj.border,
                lineHeight: props.initToObj.lineHeight,
                color: props.initToObj.color,
                height: props.initToObj.height,
                width: props.initToObj.width,
                padding: props.initToObj.padding,
                boxShadow: props.initToObj.boxShadow,
                fontColor: props.initToObj.fontColor,
                fontSize: props.initToObj.fontSize,
                fontFamily: props.initToObj.fontFamily,
                textOverride: props.initToObj.textOverride,
                lebelTextTransform: props.initToObj.lebelTextTransform,
                hoverBackground: props.initToObj.hoverBackground,
                hoverBackgroundImage: props.initToObj.hoverBackgroundImage,
                hoverBorderColor: props.initToObj.hoverBorderColor,
                hoverBorderRadius: props.initToObj.hoverBorderRadius,
                hoverBorder: props.initToObj.hoverBorder,
                hoverLineHeight: props.initToObj.hoverLineHeight,
                hoverColor: props.initToObj.hoverColor,
                hoverHeight: props.initToObj.hoverHeight,
                hoverWidth: props.initToObj.hoverWidth,
                hoverPadding: props.initToObj.hoverPadding,
                hoverBoxShadow: props.initToObj.hoverBoxShadow,
                hoverFontColor: props.initToObj.hoverFontColor,
                hoverFontSize: props.initToObj.hoverFontSize,
                hoverFontFamily: props.initToObj.hoverFontFamily,
            };
        }
    }


    componentWillReceiveProps(nextProps) {
        // Does NOT fire for some reason!!!
    }


    onTextChange = (event, fieldName) => {

        let value = event.target.value;

        let stateObj = {[fieldName]: value};

        this.setState(stateObj, () => {this.setMetadataValue()});
    };


    selectChange = (selectName, e, index, selected) => {
        let stateObj = {[selectName]: selected};

        this.setState(stateObj, () => {this.setMetadataValue()});
    };


    setMetadataValue = () => {

        let valueObj = {
            variant: this.state.variant,
            background: this.state.background,
            backgroundImage: this.state.backgroundImage,
            borderColor: this.state.borderColor,
            borderRadius: this.state.borderRadius,
            border: this.state.border,
            lineHeight: this.state.lineHeight,
            color: this.state.color,
            height: this.state.height,
            width: this.state.width,
            padding: this.state.padding,
            boxShadow: this.state.boxShadow,
            fontColor: this.state.fontColor,
            fontSize: this.state.fontSize,
            fontFamily: this.state.fontFamily,
            textOverride: this.state.textOverride,
            lebelTextTransform: this.state.lebelTextTransform,
            hoverBackground: this.state.hoverBackground,
            hoverBackgroundImage: this.state.hoverBackgroundImage,
            hoverBorderColor: this.state.hoverBorderColor,
            hoverBorderRadius: this.state.hoverBorderRadius,
            hoverBorder: this.state.hoverBorder,
            hoverLineHeight: this.state.hoverLineHeight,
            hoverColor: this.state.hoverColor,
            hoverHeight: this.state.hoverHeight,
            hoverWidth: this.state.hoverWidth,
            hoverPadding: this.state.hoverPadding,
            hoverBoxShadow: this.state.hoverBoxShadow,
            hoverFontColor: this.state.hoverFontColor,
            hoverFontSize: this.state.hoverFontSize,
            hoverFontFamily: this.state.hoverFontFamily,
        };

        // Added 3 null parameters to match other callbacks
        this.setState({metadataValue: JSON.stringify(valueObj)}, () => {this.props.setCustomMetadata(null, null, null, this.props.fieldName, "custom", this.props.metadataTag, valueObj)});
    };


    setColor = (colorObj) => {

        this.setState(colorObj, () => {this.setMetadataValue()});
    };


    render() {
        //const {} = this.props;

        //const {} = this.state;

        return (
            <div>

                <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>

                    <div style={{marginTop: '35px'}}>
                        <Select id="button-editor-format"
                                     style={{width: "150px", height: '23px'}}
                                     label="Format"
                                     variant="standard"
                                     value={this.state.variant}
                                     onChange={this.selectChange.bind(this, "variant")}
                                     tabIndex={1}
                        >
                            <MenuItem key={"0"} value={"text"}>Text</MenuItem>
                            <MenuItem key={"1"} value={"contained"}>Contained</MenuItem>
                        </Select>
                    </div>

                    <div style={{width: "20px"}}></div>

                    <div style={{display: "flex", alignItems: "left", marginTop: '10px'}}>
                        <ArgoTextFieldHook
                            fileName="argo-button-display-color"
                            fieldName="color"
                            label="Font Color (Hex)"
                            value={this.state.color}
                            onChange={this.onTextChange}
                            width="250px"
                        />

                        {this.state.variant !== "text" ?
                            <React.Fragment>
                                <div style={{width: "20px"}}></div>

                                {/*<TextField id="button-border-color" variant="standard"*/}
                                {/*           style={{width: '250px'}}*/}
                                {/*           label={"Border Color (Hex)"}*/}
                                {/*           value={this.state.borderColor}*/}
                                {/*           autoComplete="off"*/}
                                {/*           onChange={this.textChange.bind(this, 'borderColor')}*/}
                                {/*/>*/}

                                {/*<div style={{width: "20px"}}></div>*/}

                                <ArgoTextFieldHook
                                    fileName="argo-button-display-color"
                                    fieldName="background"
                                    label="Background Color (Hex)"
                                    value={this.state.background}
                                    onChange={this.onTextChange}
                                    width="400px"
                                />
                            </React.Fragment> : ""
                        }
                    </div>
                </div>

                <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>

                    <div style={{width: "170px"}}></div>

                    <ArgoTextFieldHook
                        fileName="argo-button-display-color"
                        fieldName="hoverColor"
                        label="Hover Font Color (Hex)"
                        value={this.state.hoverColor}
                        onChange={this.onTextChange}
                        width="250px"
                    />

                    {this.state.variant !== "text" ?
                        <React.Fragment>
                            <div style={{width: "20px"}}></div>


                            {/*<TextField id="button-border-hover-color" variant="standard"*/}
                            {/*           style={{width: '250px'}}*/}
                            {/*           label={"Hover Border Color (Hex)"}*/}
                            {/*           value={this.state.hoverBorderColor}*/}
                            {/*           autoComplete="off"*/}
                            {/*           onChange={this.textChange.bind(this, 'hoverBorderColor')}*/}
                            {/*/>*/}

                            {/*<div style={{width: "20px"}}></div>*/}

                            <ArgoTextFieldHook
                                fileName="argo-button-display-color"
                                fieldName="hoverBackground"
                                label="Hover Background Color (Hex)"
                                value={this.state.hoverBackground}
                                onChange={this.onTextChange}
                                width="400px"
                            />

                        </React.Fragment> : ""
                    }

                </div>

            </div>);
    }
}

ArgoButtonDisplayCustom.defaultProps = {
    showDisplay: true,
    showTitleEntry: false,
    displayLabel: "Display",
    backgroundColorOverride: "#F8F8F8"
};

ArgoButtonDisplayCustom.propTypes = {
    showDisplay: PropTypes.bool,
    showTitleEntry: PropTypes.bool,
    fieldName: PropTypes.string,
    metadataTag: PropTypes.string,
    initToObj: PropTypes.object,
    setCustomMetadata: PropTypes.func,
    displayLabel: PropTypes.string,
    backgroundColorOverride: PropTypes.string
};

export default ArgoButtonDisplayCustom;
