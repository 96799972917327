import {PureComponent} from "react";  // eslint-disable-line
import PropTypes from "prop-types";
import * as questionTypes from "./constants/questionTypeConstants";
import * as tag from "./constants/customMetadataConstants";
import {singleSelectQuestion} from "./questions/SingleSelect";
import {multiSelectQuestion} from "./questions/MultipleChoice";
import {textEntryQuestion} from "./questions/TextInput";
import * as icon from "./constants/iconConstants";
import * as display from "./constants/displayConstants";
import * as ddo from "./constants/customObjConstants";
import * as ssType from "./constants/singleSelectTypes";
import {ZIPPOSTAL_ONLY} from "../constants/questionTypeConstants";


//const styles = {};

class PortalQuestion extends PureComponent {
    constructor(props) {
        super(props);

        this.singleSelectQuestion = singleSelectQuestion.bind(this);
        this.multiSelectQuestion = multiSelectQuestion.bind(this);
        this.textEntryQuestion = textEntryQuestion.bind(this);


        //this.addressAnswer = addressAnswer.bind(this);

        this.state = {
            formattedInput: "", //TODO How to handle this? What resets it?
        };

    }

    componentDidCatch(error, info) {
        //TODO this should do something with the error
        console.log(error, info);// eslint-disable-line
    }


    formatAnswerInput = (likertIndex = 0, itemObj) => {

        const {group, question} = this.props;

        //let inputType = "text";
        //let myTest = responsiveLabel.getResponsiveLabelProperties();

        //let questionType = tag.metadataExists(question, tag.DISPLAY_SINGLE_SELECT_AS_LIKERT) ? questionTypes.LIKERT_RADIO : question.questionType;


        switch (question.questionType) {
            case questionTypes.FREE_TEXT:
                return this.textEntryQuestion(question.questionType, "text", itemObj);
            case questionTypes.EMAIL:
                return this.textEntryQuestion(question.questionType, "email", itemObj);
            case questionTypes.NUMBER:
            case questionTypes.CURRENCY:
            case questionTypes.PHONE:
            case questionTypes.ZIPPOSTAL_ONLY:
                return this.textEntryQuestion(question.questionType, "number", itemObj);
            case questionTypes.DATE:
                return this.textEntryQuestion(question.questionType, "date", itemObj);
            case questionTypes.DATE_RANGE:
                return this.textEntryQuestion(question.questionType, "date-range", itemObj);
            case questionTypes.ADDRESS_FULL:
                return this.textEntryQuestion(question.questionType, "text", itemObj, this.props);
            // return <DateRange
            //     inputType={"date"}
            //     question={question}
            //     classes={classes}
            //     answerQuestionText={this.props.answerQuestionText}
            // />;
            case questionTypes.CHECKBOX:
            case questionTypes.DYNAMIC_CHECKBOX:
            case questionTypes.LIKERT_CHECKBOX:
                return this.multiSelectQuestion(itemObj);

            case questionTypes.RADIO:
            case questionTypes.YES_NO:
            case questionTypes.DYNAMIC_RADIO:
            case questionTypes.LIKERT_RADIO:

                let answers = ddo.getAnswerOptions(question);

                if (answers.length !== 0) {
                    if ((question.questionType === questionTypes.LIKERT_RADIO) || tag.metadataExists(question, tag.DISPLAY_SINGLE_SELECT_AS_LIKERT)) {
                        answers = ddo.getAnswerOptions(((group !== undefined) && (group.answerOptionTemplates !== undefined) && (group.answerOptionTemplates.length > 0)) ? group : question);

                        // If a group LIKERT then get the itemObj for the current question
                        let itemObjQuestion = itemObj;
                        if (group !== undefined) {
                            for (let i = 0; i < itemObj.questions.length; i++) {
                                if (question.id === itemObj.questions[i].id) {
                                    itemObjQuestion = itemObj.questions[i];
                                    break;
                                }
                            }
                        }

                        return this.singleSelectQuestion(ssType.SINGLE_SELECT_LIKERT, this.props, itemObjQuestion, false, icon.getLikertInputType(answers), likertIndex);
                    } else {
                        return this.singleSelectQuestion(display.singleSelectDisplayAs(question, answers), this.props, itemObj, false);
                    }
                }
            //break;  Not needed unreachable code

            case questionTypes.STATE_ONLY:
                return this.singleSelectQuestion(ssType.SINGLE_SELECT_DDLB_STATES, this.props, itemObj, false);

            default:
                return "";
        }
    };


    render() {

        return (this.formatAnswerInput(this.props.likertIndex, this.props.itemObj));
    }
}


PortalQuestion.defaultProps = {
    showGroupKey: ""
};


PortalQuestion.propTypes = {
    //classes: PropTypes.object.isRequired,
    //theme: PropTypes.object.isRequired,
    questionnaire: PropTypes.object,
    group: PropTypes.object,
    showGroupKey: PropTypes.string,
    question: PropTypes.object.isRequired,
    questionNumber: PropTypes.string,
    itemObj: PropTypes.object.isRequired,
    answerQuestionOptions: PropTypes.func,
    answerQuestionTemplateOptions: PropTypes.func,
    answerQuestionText: PropTypes.func,
    likertIndex: PropTypes.number,
    displayMode: PropTypes.string,
    surveyEditMode: PropTypes.string
};

export default (PortalQuestion);

