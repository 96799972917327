import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PortalContainer from "../../portal/PortalContainer";
import {getCategoryTemplate} from "../../actions/categoryTemplateActions";
import {getGroupTemplate} from "../../actions/groupTemplateActions";
import {getQuestionTemplate} from "../../actions/questionTemplateActions";
import * as TEMPLATE from "../../constants/templateConstants";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import * as tag from "../../portal/constants/customMetadataConstants";

class ArgoPortalPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            componentMounted: false,
            portalCount: -1,
            allTemplatesLoaded: false,
            questionnaireTemplateGraph: null
        };
    }

    componentDidMount() {
        this.setState({componentMounted: true}); // NOTE: Patch to fix 1st PortalPreview open, having this will trigger componentDidUpdate
    }

    componentDidUpdate(nextProps) {
        this.componentWillReceiveProps(nextProps);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        let allTemplatesLoaded = (this.state.portalCount ===  nextProps.portalCount);

        if ((nextProps.createMode || (nextProps.template.templateKey !== "")) && (!allTemplatesLoaded)) {

            let categoryKeys = [];
            let groupKeys = [];
            let questionKeys = [];

            switch (nextProps.templateType) {
                case TEMPLATE.TYPE.QUESTIONNAIRE_GRAPH:
                    allTemplatesLoaded = true;
                    break;
                case TEMPLATE.TYPE.QUESTIONNAIRE:
                    categoryKeys = nextProps.template.categoryKeys;
                    allTemplatesLoaded = this.checkForAllTemplatesLoaded(categoryKeys, nextProps.categoryTemplates, nextProps.getCategoryTemplate);
                    if (allTemplatesLoaded && (categoryKeys.length > 0)) {
                        groupKeys = this.getAllKeys(nextProps.categoryTemplates, "groupKeys"); // Combine all group keys from all categories
                        allTemplatesLoaded = this.checkForAllTemplatesLoaded(groupKeys, nextProps.groupTemplates, nextProps.getGroupTemplate);
                        if (allTemplatesLoaded && (groupKeys.length > 0)) {
                            questionKeys = this.getAllKeys(nextProps.groupTemplates, "questionKeys"); // Combine all question keys from all groups
                            allTemplatesLoaded = this.checkForAllTemplatesLoaded(questionKeys, nextProps.questionTemplates, nextProps.getQuestionTemplate);
                        }
                    }
                    break;
                case TEMPLATE.TYPE.CATEGORY:
                    groupKeys = nextProps.template.groupKeys; // Combine all group keys from all categories
                    allTemplatesLoaded = this.checkForAllTemplatesLoaded(groupKeys, nextProps.groupTemplates, nextProps.getGroupTemplate);
                    if (allTemplatesLoaded && (groupKeys.length > 0)) {
                        questionKeys = this.getAllKeys(nextProps.groupTemplates, "questionKeys"); // Combine all question keys from all groups
                        allTemplatesLoaded = this.checkForAllTemplatesLoaded(questionKeys, nextProps.questionTemplates, nextProps.getQuestionTemplate);
                    }
                    break;
                case TEMPLATE.TYPE.GROUP:
                    questionKeys = nextProps.template.questionKeys; // Combine all question keys from all groups
                    allTemplatesLoaded = this.checkForAllTemplatesLoaded(questionKeys, nextProps.questionTemplates, nextProps.getQuestionTemplate);
                    break;
                case TEMPLATE.TYPE.QUESTION:
                    allTemplatesLoaded = true;
                    break;
                // No Default
            }

            if (allTemplatesLoaded) {
                let questionnaireTemplateGraph = null;

                if (nextProps.templateType === TEMPLATE.TYPE.QUESTIONNAIRE_GRAPH) {
                    questionnaireTemplateGraph = this.adjustQuestionnaireTemplateGraph(nextProps.template);
                }
                else {
                    questionnaireTemplateGraph = this.buildQuestionnaireGraphTemplateForPreview(nextProps, categoryKeys, groupKeys, categoryKeys);
                }


                this.setState({allTemplatesLoaded: true, portalCount: nextProps.portalCount, questionnaireTemplateGraph: questionnaireTemplateGraph});
            }

        }
    }

    checkForAllTemplatesLoaded = (keyArray, templates, getTemplate) => {
        let allTemplatesLoaded = true;
        for (let i= 0; i < keyArray.length; i++) {
            if (!templates.hasOwnProperty(keyArray[i])) {
                getTemplate(keyArray[i]);
                allTemplatesLoaded = false;
                break;
            }
        }
        return allTemplatesLoaded;
    }

    getAllKeys = (templates, keyProperty) => {
        let keysArray = [];
        Object.keys(templates).forEach(key => {
            for (let i = 0; i < templates[key][keyProperty].length; i++) {
                keysArray.push(templates[key][keyProperty][i]);
            }
        });
        return keysArray;
    }

    buildQuestionnaireGraphTemplateForPreview = (props) => {

        let templateClone = JSON.parse(JSON.stringify(props.template)); // Clone template object
        let categoryTemplatesClone = JSON.parse(JSON.stringify(props.categoryTemplates));
        let groupTemplatesClone = JSON.parse(JSON.stringify(props.groupTemplates));
        let questionTemplatesClone = JSON.parse(JSON.stringify(props.questionTemplates));

        let defaultQuestionnaireTemplate = {};

        if (props.templateType === TEMPLATE.TYPE.QUESTIONNAIRE) {
            defaultQuestionnaireTemplate = templateClone;
        } else {
            defaultQuestionnaireTemplate = {
                "id": 1,
                "templateKey": "questionnaireKey1",
                "name": "",
                "description": "",
                "metadata": {[tag.NO_SUBMIT]: "", [tag.DISPLAY_DEF_NAME]: "hide"},
                "questionnaireType": "QUESTIONNAIRE_COMPLEX",
                "questionTemplates": [],
                "questionCategoryTemplates": [],
            };
        }

        let defaultCategoryTemplate = {
            "id": 11,
            "templateKey": "categoryKey11",
            "name": "",
            "description": "",
            "metadata": {[tag.DISPLAY_DEF_NAME]: "hide"},
            "groupTemplates": []
        };

        let defaultGroupTemplate = {
            "id": 111,
            "templateKey": "groupKey111",
            "name": "",
            "description": "",
            "questionGroupType": "DEFAULT",
            "visible": true,
            "questionTemplates": [],
            "answerOptionTemplates": [],
            "metadata": {[tag.DISPLAY_DEF_NAME]: "hide"}
        };

        if (props.template !== null) {

            if (props.templateType === TEMPLATE.TYPE.QUESTIONNAIRE) {

                if (templateClone.id === undefined) {
                    defaultQuestionnaireTemplate.id = 1;
                    defaultQuestionnaireTemplate.templateKey = "questionnaireKey1";
                }

                defaultQuestionnaireTemplate.questionCategoryTemplates = this.getTemplatesArray(templateClone.categoryKeys, categoryTemplatesClone); // All categories for questionnaire
                for (let c = 0; c < defaultQuestionnaireTemplate.questionCategoryTemplates.length; c++) {
                    let categoryGroupKeys = defaultQuestionnaireTemplate.questionCategoryTemplates[c].groupKeys;
                    defaultQuestionnaireTemplate.questionCategoryTemplates[c].groupTemplates = [];
                    for (let g = 0; g < categoryGroupKeys.length; g++) {
                        defaultQuestionnaireTemplate.questionCategoryTemplates[c].groupTemplates.push(groupTemplatesClone[categoryGroupKeys[g]]);
                        let groupQuestionKeys = groupTemplatesClone[categoryGroupKeys[g]].questionKeys;
                        defaultQuestionnaireTemplate.questionCategoryTemplates[c].groupTemplates[g].questionTemplates = [];
                        for (let q = 0; q < groupQuestionKeys.length; q++) {
                            defaultQuestionnaireTemplate.questionCategoryTemplates[c].groupTemplates[g].questionTemplates.push(questionTemplatesClone[groupQuestionKeys[q]]);
                        }
                    }
                }

            } else if (props.templateType === TEMPLATE.TYPE.CATEGORY) {

                if (templateClone.id === undefined) {
                    templateClone.id = 11;
                    templateClone.templateKey = "categoryKey11";
                }

                defaultQuestionnaireTemplate.questionCategoryTemplates.push(templateClone);
                let categoryGroupKeys = defaultQuestionnaireTemplate.questionCategoryTemplates[0].groupKeys;
                defaultQuestionnaireTemplate.questionCategoryTemplates[0].groupTemplates = [];
                for (let g = 0; g < categoryGroupKeys.length; g++) {
                    defaultQuestionnaireTemplate.questionCategoryTemplates[0].groupTemplates.push(groupTemplatesClone[categoryGroupKeys[g]]);
                    let groupQuestionKeys = groupTemplatesClone[categoryGroupKeys[g]].questionKeys;
                    defaultQuestionnaireTemplate.questionCategoryTemplates[0].groupTemplates[g].questionTemplates = [];
                    for (let q = 0; q < groupQuestionKeys.length; q++) {
                        defaultQuestionnaireTemplate.questionCategoryTemplates[0].groupTemplates[g].questionTemplates.push(questionTemplatesClone[groupQuestionKeys[q]]);
                    }
                }

            } else if (props.templateType === TEMPLATE.TYPE.GROUP) {

                if (templateClone.id === undefined) {
                    templateClone.id = 111;
                    templateClone.templateKey = "groupKey111";
                }

                defaultQuestionnaireTemplate.questionCategoryTemplates.push(defaultCategoryTemplate);

                if (templateClone.questionGroupType === "LIKERT") {
                    let answers = templateClone.answerOptionTemplates;

                    for (var aa = 0; aa < answers.length; aa++) {
                        if (answers[aa].id === undefined) {
                            answers[aa].id = aa;
                            answers[aa].templateKey = "answerKey" + aa;
                        }
                    }
                }

                defaultQuestionnaireTemplate.questionCategoryTemplates[0].groupTemplates.push(templateClone);

                let groupQuestionKeys = defaultQuestionnaireTemplate.questionCategoryTemplates[0].groupTemplates[0].questionKeys;
                defaultQuestionnaireTemplate.questionCategoryTemplates[0].groupTemplates[0].questionTemplates = [];
                for (let q = 0; q < groupQuestionKeys.length; q++) {
                    defaultQuestionnaireTemplate.questionCategoryTemplates[0].groupTemplates[0].questionTemplates.push(questionTemplatesClone[groupQuestionKeys[q]]);
                }

                //let groups = defaultQuestionnaireTemplate.questionCategoryTemplates[0].groupTemplates;

                // In case not saved yet there will be no id or key
                // for (var g = 0; g < groups.length; g++) {
                //     if (groups[g].id === undefined) {
                //         groups[g].id = parseInt(1 + g.toString() + g.toString());
                //         groups[g].templateKey = "groupKey" + groups[g].id;
                //     }
                //     groups[g].visible = true;
                // }

                //alert(JSON.stringify(groups));

            } else if (props.templateType === TEMPLATE.TYPE.QUESTION) {
                defaultQuestionnaireTemplate.questionCategoryTemplates.push(defaultCategoryTemplate);
                defaultQuestionnaireTemplate.questionCategoryTemplates[0].groupTemplates.push(defaultGroupTemplate);

                if (templateClone.id === undefined) {
                    templateClone.id = 1111;
                    templateClone.templateKey = "questionKey1111";
                }

                templateClone.answerOptionTemplates = JSON.parse(JSON.stringify(props.answerOptionTemplates));

                for (var a = 0; a < templateClone.answerOptionTemplates.length; a++) {
                    if (templateClone.answerOptionTemplates[a].id === undefined) {
                        templateClone.answerOptionTemplates[a].id = a;
                        templateClone.answerOptionTemplates[a].templateKey = "answerKey" + a;
                    }
                }

                defaultQuestionnaireTemplate.questionCategoryTemplates[0].groupTemplates[0].questionTemplates.push(templateClone);
            }
        }

        defaultQuestionnaireTemplate = this.adjustQuestionnaireTemplateGraph(defaultQuestionnaireTemplate);

        return defaultQuestionnaireTemplate;
    };

    getTemplatesArray = (keysArray, templates) => {
        let tempTemplatesArray = [];
        for (let i = 0; i < keysArray.length; i++) {
            tempTemplatesArray.push(templates[keysArray[i]]);
        }
        return tempTemplatesArray;
    }

    adjustQuestionnaireTemplateGraph = (questionnaireTemplateGraph) => {
        // NOTE: This adds group answers to each of the questions
        // And for answers adds a selected property
        let tempQnr = questionnaireTemplateGraph;
        tempQnr["questions"] = undefined; // This is used in instances checks to determine if instance or template mode

        for (let c = 0; c < tempQnr.questionCategoryTemplates.length; c++) {

            let category = tempQnr.questionCategoryTemplates[c];

            for (let g = 0; g < category.groupTemplates.length; g++) {

                let group = category.groupTemplates[g];

                if (group.questionGroupType ===  "LIKERT") {

                    for (let i = 0; i < group.questionTemplates.length; i++) {

                        let question = group.questionTemplates[i];

                        question.answerOptionTemplates = group.answerOptionTemplates;  // Apply answers to LIKERT question

                        for (let a = 0; a < question.answerOptionTemplates.length; a++) {
                            question.answerOptionTemplates[a].selected = false; // add selected property to object
                            question.answerOptionTemplates[a].key = question.answerOptionTemplates[a].templateKey; // add selected property to object
                        }
                    }
                }
                else {
                    for (let q = 0; q < group.questionTemplates.length; q++) {

                        let question = group.questionTemplates[q];

                        if (question.answerOptionTemplates.length > 0) {
                            for (let a = 0; a < question.answerOptionTemplates.length; a++) {
                                question.answerOptionTemplates[a].selected = false; // add selected property to object
                                question.answerOptionTemplates[a].key = question.answerOptionTemplates[a].templateKey; // add selected property to object
                            }
                        }
                        else {
                            question.answerText = "";
                        }
                    }
                }
            }
        }

        return tempQnr;
    }


    render() {

        //let height = (this.props.templateType === TEMPLATE.TYPE.QUESTIONNAIRE) ? "100%" : "1000px";

        return (
            (this.state.allTemplatesLoaded && this.state.questionnaireTemplateGraph && (this.state.portalCount ===  this.props.portalCount)) &&
            <div id={"ArgoPortalPreview-container-div1" + this.props.id} style={{display: this.props.hidePortalPreview ? "none" : "inline"}}>

                <div id="ArgoPortalPreview-container-div2" style={{overflow: "hidden"}}>

                    <div style={{height: '20px'}}>&nbsp;</div>

                    <div>

                        {/*<div id={"portalPreviewUniqueIdentifier"} style={{fontSize: "large", fontWeight: "bold", paddingBottom: "24px"}}>Portal Preview</div>*/}
                        <div>
                            {(this.props.templateType !== TEMPLATE.TYPE.QUESTIONNAIRE_GRAPH) ?
                            <Divider id={"ArgoPortalPreview-divider-ArgoTemplateListDividerHook"}>
                                <Typography variant="subtitle1">Portal Preview</Typography>
                            </Divider> : ""
                            }
                        </div>

                        <div style={{overflowY: "auto"}}>
                            <div>&nbsp;</div>
                            <PortalContainer
                                portalPreview={true}
                                questionnaireTemplateGraph={this.state.questionnaireTemplateGraph}
                                portalCount={this.props.portalCount}
                            />
                        </div>

                    </div>

                    <div style={{height: '20px'}}>&nbsp;</div>

                </div>

            </div>
        );
    }
}

ArgoPortalPreview.defaultProps = {
    createMode: false,
    hidePortalPreview: false
};

ArgoPortalPreview.propTypes = {
    calledBy: PropTypes.string,
    createMode: PropTypes.bool,
    templateType: PropTypes.string,
    template: PropTypes.object,
    answerOptionTemplates: PropTypes.array,
    hidePortalPreview: PropTypes.bool,
};

function mapStateToProps(state) {
    return {
        categoryTemplates: state.categoryTemplate,
        groupTemplates: state.groupTemplate,
        questionTemplates: state.questionTemplate,
        portalCount: state.portal.portalCount
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getCategoryTemplate, getGroupTemplate, getQuestionTemplate}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArgoPortalPreview);

